﻿.nsg-btn,
a.nsg-btn {
    background-color: map-get($theme-colors, 'beige');
    color: map-get($theme-colors, 'darkblue');
    border-radius: 40px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    max-width: max-content;
    padding: 15px 33px;
    margin: 0 auto;
    text-decoration: none;
    border: none;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
        background-color: map-get($theme-colors, 'brown');
        color: map-get($theme-colors, 'beige');
        text-decoration: none;
    }

    &.no-bg {
        background-color: transparent;
        border: 1px solid map-get($theme-colors, 'darkblue');

        &:hover {
            background-color: map-get($theme-colors, 'nsg-blue');
        }
    }

    &.no-bg-beige {
        background-color: transparent;
        border: 1px solid map-get($theme-colors, 'beige');
        color: map-get($theme-colors, 'beige');

        &:hover {
            background-color: map-get($theme-colors, 'beige');
            color: map-get($theme-colors, 'darkblue');
        }
    }

    &.darkblue {
        background-color: map-get($theme-colors, 'nsg-blue');
        color: map-get($theme-colors, 'beige');

        &:hover {
            background-color: map-get($theme-colors, 'darkblue');
        }
    }

    &.beige {
        background-color: map-get($theme-colors, 'beige');
        color: map-get($theme-colors, 'darkblue');

        &:hover {
            background-color: map-get($theme-colors, 'brown');
            color: map-get($theme-colors, 'darkblue');
            text-decoration: none;
        }
    }
}
