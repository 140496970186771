﻿@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.site-footer {
  padding-top: 100px;
  padding-bottom: 100px;

  h5 {
    color: map-get($theme-colors, 'beige');
  }

  .nsg-logo {
    text-align: center;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      margin-bottom: 80px;
    }

    svg {
      max-height: 30px;
    }

    .cls-1 {
      fill: map-get($theme-colors, 'beige') !important;
    }
  }

  .link-and-info {
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    a:hover {
      color: map-get($theme-colors, 'brown');
      text-decoration: none;
    }

    display: flex;
    flex-direction: column;
    text-align: center;
    color: map-get($theme-colors, 'beige');

    p {
      margin-bottom: 0px;
    }
  }

  .bottom-area {
    position: relative;
    margin-top: 40px;

    @include media-breakpoint-up(md) {
      margin-top: 80px;
    }

    .soc-media {
      @include media-breakpoint-down(md) {
        text-align: center;
        margin-bottom: 20px;
      }

      @include media-breakpoint-up(md) {
        position: absolute;
        left: 15px;
        top: 0px;
      }

      li {
        display: inline-block;
        margin-right: 15px;

        &:last-of-type {
          margin-right: 0px;
        }

        svg {
          height: 38px;
          width: 38px;

          path {
            fill: map-get($theme-colors, 'beige') !important;
          }
        }

        &.instagram {
          svg {
            circle {
              fill: map-get($theme-colors, 'beige') !important;
            }

            path {
              fill: map-get($theme-colors, 'darkblue') !important;
            }
          }
        }
      }
    }

    .general-info {
      color: map-get($theme-colors, 'beige');
      text-align: center;

      p {
        margin-bottom: 0px;
      }

      a {
        text-decoration: underline;

        &:first-of-type {
          margin-right: 10px;
        }

        &:hover {
          color: map-get($theme-colors, 'brown');
          text-decoration: none;
        }
      }
    }

    .external-logos {
      min-width: 210px;

      @media (max-width: 767px) {
        text-align: center;
        margin-top: 20px;

        img {
          margin-bottom: 15px;
        }
      }

      @include media-breakpoint-up(md) {
        position: absolute;
        right: 15px;
        top: 0px;
      }

      img {
        display: inline-block;
        max-height: 60px;
        margin-left: 10px;
      }
    }
  }
}

.b2b .site-footer {
  h3 {
    @include media-breakpoint-down(lg) {
      font-size: 24px;
      line-height: 26px;
      margin-bottom: 8px;
    }
  }

  a,
  li,
  p {
    font-size: 20px;
    line-height: 28px;
  }

  padding: 100px 20px;

  .links {
    margin-top: 10px;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      margin-top: 70px;
      margin-bottom: 50px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      margin-right: 30px;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    a {
      position: relative;

      &:before {
        background-color: #ffbe63;
        bottom: -3px;
        content: '';
        height: 2px;
        left: 0;
        opacity: 0;
        position: absolute;
        width: 100%;
      }

      &:hover {
        text-decoration: none;

        &:before {
          animation: fade-in-right 0.3s ease-in-out forwards;
        }
      }
    }
  }

  .contactInfo {
    margin-top: 20px;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
      padding-left: 25px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    h3 {
      width: 100%;
      margin-top: 0;
    }

    li {
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .soc-media {
    position: relative;
    display: flex;

    li {
      margin-right: 15px;

      &:last-of-type {
        margin-right: 0;
      }

      svg {
        width: 47px !important;
        height: 47px !important;

        > path {
          fill: map-get($theme-colors, 'gold') !important;
        }
      }

      &.instagram svg {
        > circle {
          fill: map-get($theme-colors, 'gold') !important;
        }

        > path {
          fill: map-get($theme-colors, 'darkblue') !important;
        }
      }
    }
  }
}

.external-logos {
  position: relative;

  @include media-breakpoint-down(md) {
    margin-top: 30px;
  }

  img {
    max-width: 62px;
    margin-right: 25px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.bottom-left {
  display: flex;

  @include media-breakpoint-down(md) {
    margin-top: 16px;
    flex-direction: column;
  }

  a {
    font-size: 16px !important;
    line-height: 18px !important;
  }
}

.general-info {
  margin-top: 16px;

  @include media-breakpoint-up(md) {
    margin-top: 0;
    margin-left: 30px;
  }

  p {
    font-size: 16px !important;
    line-height: 18px !important;
    margin-bottom: 5px;

    &:first-of-type {
      opacity: 0.7;
    }
  }
}
