body.osolo-nsg {
  &.osolo-nsg {
    font-family: $font-main;

    .navbar-brand {
      color: map-get($theme-colors, 'beige');
      min-width: unset;
    }
  }

  p,
  li,
  a {
    font-size: 18px;
    line-height: 26px;
  }

  strong {
    font-family: $font-bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-bold;
    letter-spacing: 0;
    margin-bottom: 1rem;
    margin-top: 0.75rem;
  }

  h1 {
    font-size: 42px;
    line-height: 42px;

    @media (min-width: 768px) {
      font-size: 50px;
      line-height: 50px;
    }
  }

  h2 {
    font-size: 36px;
    line-height: 38px;

    @media (min-width: 768px) {
      font-size: 40px;
      line-height: 40px;
    }
  }

  h3 {
    font-size: 30px;
    line-height: 32px;

    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 34px;
    }
  }

  h4 {
    font-size: 24px;
    line-height: 32px;
  }
}
