@font-face {
  font-family: 'TTCommons-regular';
  font-weight: normal;
  src: url('../fonts/56184c9a-0155-4961-a836-516c2a37949d.woff2')
    format('woff2');
  src: url('../fonts/941bd4ef-6d96-4cc3-b891-b967fb693919.woff') format('woff');
}

@font-face {
  font-family: 'TTCommons-bold';
  font-weight: 700;
  src: url('../fonts/7d35d1f9-668e-42b2-a6b2-9009f0aa3ed4.woff2')
    format('woff2');
  src: url('../fonts/23a70446-ff80-4d9d-a429-94bc68bb5c97.woff') format('woff');
}
