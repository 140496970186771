﻿.c-textImage {
    padding-top: 10px;
    padding-bottom: 35px;

    @include media-breakpoint-up (md) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &__textArea {
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down (md) {
            margin-bottom: 20px;
            order: 2;
            margin-top: 10px;
        }

        .wrap {
            width: min(100%, 350px);
            text-align: center;
        }

        &.right {
            @include media-breakpoint-up (md) {
                order: 2;
            }
        }
    }

    &__imageArea .wrap {
        position: relative;
        height: 270px;

        @include media-breakpoint-up (md) {
            min-height: 500px;
            height: auto;
        }

        img {
            height: 100%;
            left: 0;
            max-width: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &.subcategory-area {
        .row {
            margin-bottom: 30px;

            @include media-breakpoint-down (md) {
                .c-textImage__textArea {
                    order: 2;
                }
            }

            @include media-breakpoint-up (md) {
                &:nth-child(even) {
                    .c-textImage__textArea {
                        order: 2;
                    }
                }
            }
        }
    }
}
