﻿.osolo-nsg {
  .sticky-top {
    left: 0;
    right: 0;
    background: map-get($theme-colors, 'darkblue');
    color: map-get($theme-colors, 'beige');
    margin-bottom: 20px;

    @include media-breakpoint-down(lg) {
      bottom: 0;
      top: unset !important;
      background: map-get($theme-colors, 'darkblue');
      position: fixed;
      margin-bottom: 0px;
    }

    &.hasHero {
      background: transparent;
      margin-bottom: -80px;

      @include media-breakpoint-down(lg) {
        background: map-get($theme-colors, 'darkblue');
        margin-bottom: 0px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
    .sticky-top.open {
        position: fixed;
        top: 0 !important;
        bottom: 0;

        .bottom-section {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding-right: 15px;

            .main-menu {
                display: block;
                width: 60%;
                position: fixed;
                left: 50%;
                top: 72px;
                transform: translateX(-50%);
            }

            .open-search {
                left: 15px;
            }

            .select-language,
            .select-currency {
                display: block;
                z-index: 11;
            }

            &::before {
                content: '';
                background: map-get($theme-colors, 'darkblue');
                height: 55px;
                position: absolute;
                top: -50px;
                width: 100%;
                left: 0;
                z-index: 10;
            }
        }
    }

    .sticky-top.open-search {
        position: fixed;
        top: 0 !important;
        bottom: 0;

        .bottom-section {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding-right: 15px;

            .navbar-form {
                display: block;
                position: fixed;
                width: min(90%, 300px);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: transparent;

                .btn-search {
                    order: 2;
                }

                input {
                    border-bottom: 1px solid map-get($theme-colors, 'beige') !important;
                    padding-left: 0px;
                    margin-left: 25px;

                    &:focus {
                        outline: none;
                    }
                }
            }

            .open-search {
                left: 15px;
            }
        }
    }
}

@media (max-width: 500px) {
  .sticky-top.open {
    .bottom-section {
      .main-menu {
        width: 90%;
        height: calc(100% - 170px);
      }
    }
  }
}

@media (min-width: 1199.98px) {
  .osolo-nsg.scrolled {
    .sticky-top {
      position: sticky;
      background: map-get($theme-colors, 'darkblue');
      color: map-get($theme-colors, 'beige');
    }
  }

  .sticky-top.open-search {
    .bottom-section {
      .navbar-form {
        display: block;
        width: 240px;
        max-width: 240px;
      }
    }
  }
}

@media (min-width: 1330px) {
  .sticky-top.open-search {
    .bottom-section {
      .navbar-form {
        width: 270px;
        max-width: 270px;
      }
    }
  }
}

.mobile-logo {
  background: map-get($theme-colors, 'darkblue');
  padding: 0px 15px;
  width: 100%;
  text-align: center;

  .navbar-brand {
    @include media-breakpoint-down(md) {
      padding: 20px 0px;
    }
  }

  &.hasHero {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    background: transparent;
  }

  svg {
    max-width: 102px;
    width: 102px;
  }

  .currencySelect select {
    background-color: transparent;
    background-image: url('/assets/images/expand-more.svg');
    background-size: 15px;
    border-radius: 15px;
    border-color: map-get($theme-colors, 'beige');
    color: map-get($theme-colors, 'beige');
  }

  @media (min-width: 1199.98px) {
    display: none;
  }
}

.header-container {
    header {
        justify-content: center;

        @media (min-width: 1199.98px) {
            padding-top: 20px;
        }

        @media (min-width: 1330px) {
            padding-left: 50px;
            padding-right: 50px;
        }

        .navbar-brand {
            width: 102px;
            min-width: 80px !important;
            margin-right: 0;
            color: map-get($theme-colors, 'beige');

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }

    .bottom-section {
        flex-basis: 100%;
        display: flex;
        align-items: center;
        position: relative;
        height: 60px;

        @media (min-width: 1199.98px) {
            justify-content: flex-end;
        }

        @include media-breakpoint-down(lg) {
            justify-content: flex-end;
        }

        @include media-breakpoint-down(lg) {
            .main-menu {
                display: none;
            }
        }

        .open-search {
            @include media-breakpoint-down(lg) {
                position: absolute;
                left: 0;
            }

            .icon-close {
                display: none;
            }
        }

        .navbar-form {
            top: unset;
            color: map-get($theme-colors, 'beige');
            display: none;
            width: 0px;
            transition: width 0.3s ease;

            .btn-search {
                color: map-get($theme-colors, 'beige');
            }

            .form-control {
                color: map-get($theme-colors, 'beige');

                &::placeholder {
                    color: map-get($theme-colors, 'beige');
                    opacity: 1;
                }
            }
        }

        .navbar {
            flex-flow: row nowrap;
            justify-content: flex-start;
            position: initial;

            @media (min-width: 1199.98px) {
                position: absolute;
                left: 20px;
            }

            @include media-breakpoint-down(lg) {
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }

        .navbar-toggler {
            color: map-get($theme-colors, 'beige');
            display: none !important;

            @include media-breakpoint-down(lg) {
                display: block !important;
            }
        }

        .navbar-nav {
            flex-direction: row;

            @include media-breakpoint-down(lg) {
                flex-direction: column;
                max-height: 75vh;
                overflow: hidden;
                overflow-y: scroll;
                background: map-get($theme-colors, 'darkblue');
            }

            @media (min-width: 1199.98px) {
                > li {
                    margin-right: 20px;
                }
            }

            li {
                color: map-get($theme-colors, 'beige');

                a {
                    color: map-get($theme-colors, 'beige');
                    padding: 4px 8px;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .nav-link {
                    padding-right: 0.5rem;
                    padding-left: 0.5rem;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;

                    &.activeProduct {
                        color: map-get($theme-colors, 'gold');
                    }
                }

                @include media-breakpoint-down(lg) {
                    &.nav-item {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: space-between;
                        width: 90%;
                        margin: 0px auto 20px auto;
                    }

                    .nav-link {
                        font-family: $font-bold;
                        font-size: 30px;
                        position: relative;

                        &::after {
                            content: none;
                        }
                    }

                    .dropdown-arrow {
                        width: 15px;
                        height: 15px;
                        background: transparent;
                        text-indent: -9999px;
                        border-top: 3px solid map-get($theme-colors, 'beige');
                        transform: rotate(-135deg);
                        border-left: 3px solid map-get($theme-colors, 'beige');
                        margin-top: -7px;
                        transition: transform 0.3s ease;

                        &.open {
                            transform: rotate(45deg);
                            margin-top: 7px;
                        }
                    }

                    .dropdown-arrow.activeProduct {
                        border-top: 3px solid map-get($theme-colors, 'gold');
                        border-left: 3px solid map-get($theme-colors, 'gold');
                    }
                }

                @media (min-width: 1199.98px) {
                    .dropdown-arrow {
                        display: none;
                    }
                }
            }

            .dropdown {
                .dropdown-menu {
                    background: map-get($theme-colors, 'darkblue');
                    color: map-get($theme-colors, 'beige');
                    border-radius: 0px;
                    flex-basis: 100%;
                    padding-left: 0px;
                    border: none;

                    .dropdown-item:hover,
                    .dropdown-item:focus {
                        background: map-get($theme-colors, 'darkblue');
                        color: map-get($theme-colors, 'beige');
                    }

                    @media (min-width: 1199.98px) {
                        position: absolute;
                    }
                }

                @media (min-width: 1199.98px) {
                    &:hover .dropdown-menu {
                        display: block;
                        margin-top: 0;
                    }
                }
            }
        }

        .select-profile {
            color: map-get($theme-colors, 'beige');

            @media (max-width: 1199.97px) {
                position: absolute;
                top: -35px;
                left: 10px;
            }

            @media (min-width: 1199.98px) {
                border-right: 1px solid #f1e9d6;
                margin-right: 7px;
            }

            a {
                color: map-get($theme-colors, 'beige');
                position: relative;
                font-size: 16px;

                &.active {
                    &::before {
                        content: '';
                        height: 2px;
                        width: 100%;
                        position: absolute;
                        bottom: 1px;
                        background-color: map-get($theme-colors, 'beige');
                    }
                }

                &:first-of-type {
                    margin-right: 8px;
                }

                &:hover {
                    text-decoration: none;

                    &::before {
                        content: '';
                        height: 2px;
                        width: 100%;
                        position: absolute;
                        bottom: 1px;
                        background-color: map-get($theme-colors, 'beige');
                    }
                }
            }

            @media (max-width: 1199.98px) {
                display: none;
                left: 20px;
            }
        }

        .select-language {
            color: map-get($theme-colors, 'beige');

            @media (max-width: 1199.97px) {
                position: absolute;
                top: -35px;
                left: 10px;
            }

            @media (min-width: 1199.98px) {
                border-right: 1px solid #f1e9d6;
                margin-right: 7px;
                padding-right: 7px;
            }

            a {
                color: map-get($theme-colors, 'beige');
                position: relative;
                font-size: 16px;

                &.active {
                    &::before {
                        content: '';
                        height: 2px;
                        width: 100%;
                        position: absolute;
                        bottom: 1px;
                        background-color: map-get($theme-colors, 'beige');
                    }
                }

                &:first-of-type {
                    margin-right: 8px;

                    &::after {
                        position: absolute;
                        right: -9px;
                        top: -4px;
                        content: '•';
                        color: #f1e9d6;
                        font-weight: 700;
                    }
                }

                &:hover {
                    text-decoration: none;

                    &::before {
                        content: '';
                        height: 2px;
                        width: 100%;
                        position: absolute;
                        bottom: 1px;
                        background-color: map-get($theme-colors, 'beige');
                    }
                }
            }

            @media (max-width: 1199.98px) {
                display: none;
                left: 20px;
            }
        }

        .select-currency {
            @media (max-width: 1199.97px) {
                position: absolute;
                top: -35px;
                right: 10px;
            }

            @media (min-width: 1199.98px) {
                border-right: 1px solid #f1e9d6;
                margin-right: 7px;
                padding-right: 7px;
            }

            input[type='radio'] {
                appearance: none;
                display: none;

                &:checked + label {
                    &::before {
                        content: '';
                        height: 2px;
                        width: 100%;
                        position: absolute;
                        bottom: 2px;
                        background-color: map-get($theme-colors, 'beige');
                    }
                }
            }

            label {
                position: relative;
                margin-bottom: 0px;
                font-size: 16px;
                line-height: 20px;
                cursor: pointer;

                &:first-of-type {
                    margin-right: 5px;

                    &::after {
                        position: absolute;
                        right: -9px;
                        top: 0;
                        content: '•';
                        color: #f1e9d6;
                        font-weight: 700;
                    }
                }

                &:hover {
                    &::before {
                        content: '';
                        height: 2px;
                        width: 100%;
                        position: absolute;
                        bottom: 2px;
                        background-color: map-get($theme-colors, 'beige');
                    }
                }
            }

            @media (max-width: 1199.98px) {
                display: none;
                right: 20px;
            }
        }

        .bottom-right {
            display: flex;
            align-items: center;

            @include media-breakpoint-down(lg) {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);

                .js-minibasket-empty-text {
                    display: none;
                }
            }

            @media (min-width: 1199.98px) {
                right: 0;
                border-right: 1px solid map-get($theme-colors, 'beige');
                margin-right: 9px;
            }
        }

        .minibasket {
            color: map-get($theme-colors, 'beige');
            font-size: 16px;
            line-height: 20px;
            margin-right: 7px;
            text-decoration: none !important;

            .js-minibasket-empty-text {
                display: none;
            }

            span {
                font-size: 16px;
                line-height: 20px;
            }

            svg {
                rect {
                    fill: map-get($theme-colors, 'beige');
                }
            }

            &.has-items {
                .item-qty {
                    background: map-get($theme-colors, 'beige');
                    color: map-get($theme-colors, 'darkblue');
                    height: 18px;
                    width: 18px;
                    border-radius: 100%;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            &.is-empty {
                .item-qty {
                    display: none;
                }
            }
        }
    }
}

.breadcrumb {
  @include media-breakpoint-down(md) {
    display: none;
  }
  margin-bottom: 20px !important;

  @media (min-width: 1199.98px) {
    padding-left: 30px;
  }

  li {
    font-size: 14px;
    line-height: 16px;

    a {
      color: map-get($theme-colors, 'brown');
      font-size: 14px;
      line-height: 16px;
    }

    span {
      color: map-get($theme-colors, 'brown');
      margin-left: 2px;
      margin-right: 4px;
    }

    &:last-of-type {
      span {
        display: none;
      }
    }
  }
}

.b2b {
    .header-container .bottom-section .navbar-nav {
        li {
            @include media-breakpoint-up(lg) {
                margin-right: 0;
            }
        }

        > .nav-item > .nav-link {
            @include media-breakpoint-up(lg) {
                padding-left: 1.1rem;
                padding-right: 1.1rem;
            }
        }
    }

    .open {
        .open-search {
            @include media-breakpoint-down(lg) {
                left: 0 !important;
            }
        }

        .bottom-section {
            @include media-breakpoint-down(lg) {
                width: calc(100% - 30px) !important;
                left: 15px !important;
                padding-right: 0 !important;
                background-color: #1b2734;
            }
        }
    }

    .bottom-right,
    .minibasket,
    .select-currency {
        margin-right: 20px !important;
    }

    .bottom-right {
        @include media-breakpoint-down(lg) {
            left: calc(75% - 22.5px) !important;
        }
    }

    .select-currency {
        padding-right: 20px !important;
    }

    .select-profile {
        margin-right: 20px !important;

        @include media-breakpoint-down(lg) {
            display: block !important;
            top: 50% !important;
            transform: translateY(-50%) !important;
            left: 25% !important;
        }

        a {
            margin-right: 20px !important;
            display: block;
            width: 20px;
            height: 20px;
        }

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
