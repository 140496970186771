.c-productInfo {
	table {
		color: #F1E9D6;
	}

	&__heading {
		.btn {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-family: $font-bold;
			color: #F1E9D6;
			text-transform: none;
			letter-spacing: normal;
			font-size: 24px;
			line-height: 32px;

			svg {
				transform: rotate(180deg);
			}

			&.collapsed {
				svg {
					transform: none;
				}
			}
		}
	}
}
