﻿.c-dailyQuotes {
    margin-top: 45px;
    padding-top: 30px;
    padding-bottom: 30px;

    @include media-breakpoint-up (md) {
        margin-top: 80px;
        margin-bottom: 0px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    background: linear-gradient(230deg, #B5B2A8 0%, #f1e9d6 30%, #f1e9d6 70%, #B5B2A8 100%);


    &__textArea {
        color: map-get($theme-colors, 'nsg-blue');
        text-align: center;
        width: min(90%, 670px);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 34px;

        a {
            color: map-get($theme-colors, 'nsg-blue');
        }

        a:hover {
            text-decoration: none;
            opacity: 0.8;
        }
    }

    &__prices {
        display: flex;
        justify-content: space-between;
        color: map-get($theme-colors, 'nsg-blue');
        flex-wrap: wrap;
        width: min(90%, 960px);
        text-align: center;
        margin: 0 auto;

        div {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            p {
                font-family: $font-bold;
                font-size: 18px;

                &:first-child {
                    margin-right: 20px;
                    font-size: 24px;
                }
            }

            @include media-breakpoint-down (md) {
                width: 51%;
                margin: auto;
            }

            @media (max-width: 500px) {
                width: 85%;
                margin: auto;
            }

            @media (max-width: 350px) {
                width: 95%;
                margin: auto;
            }
        }

        > p:last-child {
            flex-basis: 100%;
            font-size: 14px;
            text-transform: uppercase;
            margin-top: 10px;
        }

        .increase,
        .drop {
            display: flex;

            svg {
                width: 15px;
                height: 15px;
                margin-left: 5px;
                margin-top: 5px;
            }
        }

        .increase {
            svg {
                transform: rotate(-45deg);
            }
        }

        .drop {
            svg {
                transform: rotate(45deg);
            }
        }
    }
}
