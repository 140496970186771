﻿.c-newsletter {
    background: linear-gradient( 130deg, #f1e9d6 0%, #1b2734 26%, #1b2734 51%, #f1e9d6 100% );
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    color: map-get($theme-colors, 'beige');

    #mc_embed_signup #mce-success-response,
    #mc_embed_signup #mce-error-response {
        color: map-get($theme-colors, 'beige');
        width: 90%;
        font-weight: normal;
    }

    form,
    #mc_embed_signup_scroll {
        display: flex;
        width: min(100%, 550px);
        margin: auto;
        flex-wrap: wrap;

        .mc-field-group {
            width: auto !important;
        }

        input[type='email'] {
            flex-grow: 1;
            background: transparent;
            border: none !important;
            border-bottom: 1px solid #f1e9d6 !important;
            border-radius: 0px;
            padding-left: 0;
            max-width: 100%;
            font-size: 14px;
            color: map-get($theme-colors, 'beige');
            width: auto !important;
            min-width: 200px;

            @media (min-width: 508px) {
                min-width: 270px;
            }

            @media (min-width: 670px) {
                min-width: 330px;
            }

            &:focus-visible {
                outline: none;
            }

            &::placeholder {
                color: map-get($theme-colors, 'beige');
                opacity: 1;
            }
        }

        #mce-responses {
            flex-basis: 100%;
        }

        .mce_inline_error {
            border: none;
        }

        input[type='submit'] {
            margin: auto 10px;
            border-radius: 40px;
            font-size: 15px;
            line-height: 6px;
            margin-top: 4px;

            &:hover {
                color: map-get($theme-colors, 'darkblue');
            }
        }
    }
}

footer {
  .c-newsletter {
    background: transparent;
    padding-top: 40px;
    padding-bottom: 0px;

    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.c-newsletterb2b {
    background-color: map-get($theme-colors, 'beige');
    padding: 20px;
    color: map-get($theme-colors, 'darkblue');

    #mc_embed_signup #mce-success-response,
    #mc_embed_signup #mce-error-response {
        color: map-get($theme-colors, 'darkblue');
        width: 90%;
        font-weight: normal;
    }

    #mc_embed_signup .mc-field-group input.gdpr {
        display: inline-block;
        width: auto;
        padding: 8px 0;
        text-indent: 2%;
        margin-right: 5px;
    }

    p {
        font-size: 20px;
        line-height: 28px;
    }

    form,
    #mc_embed_signup_scroll {
        display: flex;
        width: min(100%, 550px);
        margin: 0 !important;
        flex-wrap: wrap;

        .mc-field-group {
            width: auto !important;
            display: flex;
            align-items: flex-end;


            &.input-wrapper {
                margin-bottom: 20px;

                label {
                    display: none;
                }

                #mce-EMAIL-error {
                    position: absolute;
                    bottom: -10px;
                }

                .mce_inline_error:last-of-type {
                    display: none !important;
                }
            }

            .content__gdpr {
                display: flex;
                max-width: 95%;
                align-items: flex-start;

                .mc-field-group {
                    margin-top: 5px;
                }

                a {
                    color: #ffbe63;

                    &:hover {
                        color: #ffbe63;
                        text-decoration: underline;
                    }
                }

                p {
                    margin-left: 15px;
                }

                .mce_inline_error:not(.gdpr) {
                    background: #ffbe63 !important;
                    position: absolute;
                    bottom: -55px;
                    width: max-content;
                    color: white !important;
                }
            }
        }

        input[type='email'] {
            flex-grow: 1;
            background: transparent;
            border: none !important;
            border-bottom: 2px solid black !important;
            border-radius: 0px;
            padding-left: 0;
            max-width: 100%;
            font-size: 14px;
            color: map-get($theme-colors, 'darkblue');
            width: auto !important;
            min-width: 200px;

            @media (min-width: 508px) {
                min-width: 270px;
            }

            @media (min-width: 670px) {
                min-width: 330px;
            }

            &:focus-visible {
                outline: none;
            }

            &::placeholder {
                color: map-get($theme-colors, 'darkblue');
                opacity: 1;
            }
        }

        #mce-responses {
            flex-basis: 100%;
        }

        .mce_inline_error {
            border: none;
        }

        input[type='submit'] {
            border-bottom: 2px solid #1b2734;
            border-radius: 0;
            color: #1b2734;
            font-size: 15px;
            line-height: 6px;
            margin-top: 4px;
            background-color: transparent;
            margin-bottom: 0;
            margin-left: 15px;
            margin-right: 0;
            text-transform: uppercase;
            font-size: 16px;
            margin: 0;
            padding-top: 12px;
            padding-bottom: 12px;
            margin-left: 15px;

            &:hover {
                color: map-get($theme-colors, 'darkblue');
                background-color: #b7a7a0;
            }
        }
    }
}
