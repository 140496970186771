.c-loginForm {
    label {
        margin-bottom: 0;
    }

    &__buttons {
        margin-top: 30px;
        display: flex;
        align-items: flex-end;
    }

    &__login-btn {
        margin: 0 15px 0 0;
        min-width: 120px;
    }

    &__separator {
        margin: 30px 0;
    }
}
