﻿.c-nsgSlider {
  .slick-slider {
      &.product-list {
          justify-content: flex-start;
      }
    .slick-slide {
      .product-image {
        padding-top: 0px;

        img {
          margin: auto;
          position: relative;
        }
      }

      .product-name {
        font-family: $font-bold;
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 0px;
        line-height: 20px;
      }

      .info {
        font-size: 14px;
        line-height: 18px;
      }

      .bottom-info {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .time-stamp {
          font-size: 14px;
          line-height: 18px;
        }

        p {
          font-size: 14px;
          margin-bottom: 0;
          line-height: 14px;

          &.price {
            font-family: $font-bold;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 0;
          }
        }

        button {
          margin-left: auto;
          margin-right: 15px;
        }
      }
    }

    .slick-dots {
      margin-top: 30px;

      li {
        height: 0;
        width: 16px;
        border-bottom: 18px solid map-get($theme-colors, 'darkbeige');
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        box-sizing: content-box;
        margin: 0px 7px !important;
        background-color: transparent !important;
        background-clip: padding-box;
        border-top: 10px solid transparent;
        opacity: 0.5 !important;
        min-width: unset !important;

        &.slick-active {
          background-color: transparent !important;
          opacity: 1 !important;
        }

        button:focus-visible {
          outline: none;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .c-productSlider,
  .c-newsSlider {
    .top-section .right {
      svg:hover {
        fill: map-get($theme-colors, 'beige');
      }
    }
  }
}

.c-productSlider,
.c-newsSlider {
  padding-top: 50px;

  .top-section {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;

    .right {
      display: flex;
      margin-left: 50px;

      .prev {
        margin-right: 15px;
      }

      svg {
        width: 26px;
        fill: map-get($theme-colors, 'darkbeige');
        transition: fill 0.3s ease-in;
        cursor: pointer;
      }
    }
  }

  a {
    &:hover {
      .product-name,
      .info,
      .time-stamp,
      .price,
      h3 {
        color: map-get($theme-colors, 'grey');
      }

      text-decoration: none;
    }
  }

  .product-list {
    .product-name {
      font-family: $font-bold;
      font-size: 18px;
    }

    .info {
      font-size: 14px;
      line-height: 18px;
    }

    .bottom-info {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      justify-content: space-between;

      .time-stamp {
        font-size: 14px;
        line-height: 18px;
      }

      p {
        font-size: 14px;

        &.price {
          font-family: $font-bold;
          font-size: 18px;
        }
      }
    }
  }
}

.c-newsSlider,
.c-productSlider {
  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .slick-slider {
    margin-left: -15px;

    .slick-dots {
      padding-left: 15px;
    }
  }

  .slick-slide {
    a {
      padding-left: 15px;
      padding-right: 0px;
    }
  }

  @media (min-width: 601px) {
    .slick-slider {
      margin-left: -50px;

      .slick-dots {
        padding-left: 50px;
      }
    }

    .slick-slide {
      a {
        padding-left: 70px;
        padding-right: 0px;
      }
    }
  }
}

.c-newsSlider,
.c-newsList {
  a {
    padding: 15px;
    color: map-get($theme-colors, 'darkblue') !important;
  }

  .news-image {
    height: 270px;
    width: 100%;

    img {
      object-fit: cover;
      height: 100%;
    }
  }
}

.c-quoteSlider {
  background: linear-gradient(
    130deg,
    #f1e9d6 0%,
    #1b2734 26%,
    #1b2734 51%,
    #f1e9d6 100%
  );
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  color: map-get($theme-colors, 'beige');

  .slick-track {
    display: flex;
    align-items: center;
  }

  &__quote {
    width: min(90%, 520px);
    margin: auto;
    position: relative;
    padding-top: 100px;

    @media (min-width: 768px) {
      width: min(90%, 720px);
    }

    @media (min-width: 1100px) {
      width: min(90%, 920px);
    }

    svg {
      position: absolute;
      z-index: 1;
      top: 20px;
      width: 166px;
      left: -10px;
      opacity: 0.2;

      path {
        fill: map-get($theme-colors, 'beige');
      }
    }

    .c-quoteSlider__text {
      position: relative;
      z-index: 2;

      p {
        font-family: $font-bold;
        font-size: 24px;
        line-height: 27px;

        @media (min-width: 768px) {
          font-size: 28px;
          line-height: 30px;
        }

        @media (min-width: 1100px) {
          font-size: 33px;
          line-height: 35px;
        }
      }
    }
  }
}

.b2b .c-nsgSlider .slick-slider .slick-dots {
  > li {
    border: none;
    border-radius: 50%;
    height: 12px !important;
    width: 12px;
    box-sizing: content-box;
    margin: 0px 5px !important;
    opacity: 0.5 !important;
    min-width: unset !important;

    &.slick-active {
      opacity: 1 !important;
    }

    button {
      padding: 0;
      border-radius: 50%;
      background-color: #6e6e6e;

      &:focus-visible {
        outline: none;
      }
    }
  }
}
