﻿.osolo-nsg .hero-unit {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    min-height: 350px;

    &:after {
        background: linear-gradient( 180deg,rgba(0,0,0,.7) 8%,rgba(27,39,52,.2) 97%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    @include media-breakpoint-up (md) {
        height: 40vh;
        min-height: 450px;
    }

    @include media-breakpoint-up (lg) {
        min-height: 540px;
    }

    h1 {
        position: relative;
        z-index: 3;
        color: map-get($theme-colors, 'beige');
        font-size: 42px;
        line-height: 42px;
        max-width: unset;

        @media (min-width: 768px) {
            font-size: 50px;
            line-height: 50px;
            max-width: 40vw;
        }
    }

    .hero-img {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        img {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            max-width: 100%;
            object-fit: cover;
        }
    }
}

.osolo-nsg {
    .excerpt-text {
        p {
            font-size: 20px;
            line-height: 28px;

            a {
                font-size: 20px;
                line-height: 28px;
                color: map-get($theme-colors, 'nsg-blue');
                text-decoration: underline;
            }

            a:hover {
                text-decoration: none;
                opacity: 0.8;
            }
        }

        a {
            font-size: 20px;
            line-height: 28px;
            color: map-get($theme-colors, 'gold');
        }

        @media (min-width: 992px) {
            &.col-lg-7 {
                flex: 0 0 60.33333333%;
                max-width: 60.33333333%;
            }
        }
    }
}
