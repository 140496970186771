﻿.ohno {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
}

#ContactForm {
    background-color: map-get($theme-colors, 'nsg-blue');
    color: map-get($theme-colors, 'beige');
    padding: 30px;
    width: 100%;

    @media (min-width: 992px) {
        padding: 60px;
    }

    h3 {
        text-align: center;
    }

    form {
        max-width: 348px;
        margin: 30px auto 0px;

        > div {
            margin-bottom: 10px;
            font-size: 14px;
            margin-top: 20px;
        }

        input:not([type="submit"]),
        select {
            flex-grow: 1;
            background: transparent;
            border: none;
            border-bottom: 1px solid #f1e9d6;
            padding-left: 0;
            max-width: unset;
            font-size: 14px;
            color: map-get($theme-colors, 'beige');

            &:focus-visible {
                outline: none;
            }

            &::placeholder {
                color: map-get($theme-colors, 'beige');
                opacity: 1;
                text-transform: uppercase;
                font-size: 14px;
            }
        }

        input[type="submit"] {
            margin-top: 20px;
            margin-left: 0px;
            border-radius: 40px;
            font-size: 15px;
            line-height: 6px;
            width: 176px;
            max-width: unset;
        }

        textarea {
            background: transparent;
            border: 1px solid #f1e9d6;
            padding-left: 6px;
            max-width: unset;
            font-size: 14px;
            color: map-get($theme-colors, 'beige');
            margin-top: 30px;

            &:focus-visible {
                outline: none;
            }

            &::placeholder {
                color: map-get($theme-colors, 'beige');
                opacity: 1;
                text-transform: uppercase;
                font-size: 14px;
            }
        }

        a {
            color: map-get($theme-colors, 'beige');
            text-decoration: underline;
            font-size: 14px;
        }
        /* Customize the label (the container) */
        .checkboxContainer {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 14px;
            user-select: none;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                background-color: map-get($theme-colors, 'nsg-blue');
                border: 3px solid map-get($theme-colors, 'beige');
                border-radius: 7px;

                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 6px;
                    top: 3px;
                    width: 5px;
                    height: 10px;
                    border: solid map-get($theme-colors, 'beige');
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }

            &:hover input ~ .checkmark {
                background-color: map-get($theme-colors, 'nsg-blue');
            }

            input:checked ~ .checkmark {
                background-color: map-get($theme-colors, 'nsg-blue');
            }

            input:checked ~ .checkmark:after {
                display: block;
            }
        }
    }
}