.c-login-prompt {
	color: #f1e9d6;
	background-color: #1b2734;
	padding: 20px;

	h5 {
		margin-top: 0 !important;
	}

	a {
		margin: 0;
		text-decoration: none;
	}
}
