﻿.c-imageCover-area {
    min-height: 250px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    margin-top: 40px;
    margin-bottom: 40px;

    @include media-breakpoint-up (md) {
        min-height: 650px;
        padding: 100px;
    }

    &::after {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 8%, rgba(27, 39, 52, 0.2) 97%);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    &__text {
        position: relative;
        z-index: 9;
        color: map-get($theme-colors, 'beige');
        text-align: center;

        a {
            color: map-get($theme-colors, 'beige');
        }
    }
}
