.c-registerForm {
  label {
    margin: 0;
  }

  .field-validation-error {
    color: red;
  }

  &__checkbox {
    input {
      height: 20px;
      width: 20px;
    }
  }

  &__policy {
    font-size: 14px !important;
  }
}
