﻿.carousel {
    .carousel-indicators {
        left: 0;
        right: 0;
        bottom: 30px;

        li {
            height: 0;
            width: 16px;
            border-bottom: 18px solid map-get($theme-colors, 'beige');
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            box-sizing: content-box;
            margin: 0px 7px;
            min-width: unset !important;

            &.active {
                background-color: map-get($theme-colors, 'beige');
            }
        }
    }
}

.carousel-inner {
    height: 80vh;

    @include media-breakpoint-up (md) {
        height: 100vh;
    }

    .carousel-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-decoration: none;

        &:after {
            background: linear-gradient( 180deg,rgba(0,0,0,.5) 8%,rgba(27,39,52,0) 97%);
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        picture {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            max-height: unset;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .text-area {
            position: relative;
            z-index: 10;
            text-align: center;
            width: min(90%, 500px);

            h1 {
                color: map-get($theme-colors, 'beige');
                max-width: 450px;
                margin: auto;
            }

            a {
                margin-top: 20px;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}

.b2b .carousel-indicators li {
    border: none !important;
    border-radius: 50%;
    height: 20px !important;
    width: 20px !important;
    min-width: 0 !important;
}
