﻿.product-list {
  a.product {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;

    @media (min-width: 601px) {
      padding-left: 35px;
      padding-right: 35px;
      margin-bottom: 40px;
    }

    .product-image {
      .campaign {
        position: absolute;
        left: 0;
        bottom: 10%;
        padding: 5px;
        width: 162px;
        background: linear-gradient(
          45deg,
          #1b2734 0%,
          #324355 45%,
          #324355 45%,
          #324355 46%,
          #f1e9d6 100%
        );

        p {
          font-family: $font-bold;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 2px;
          color: map-get($theme-colors, 'beige');
        }
      }
    }

    .product-name {
      font-family: $font-bold;
      font-size: 18px;
      margin-bottom: 0px;
    }

    .info {
      font-size: 14px;
      line-height: 18px;
    }

    .bottom-info {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      flex-wrap: wrap;

      .time-stamp {
        font-size: 14px;
        line-height: 18px;
      }

      p {
        font-size: 14px;

        &.price {
          font-family: $font-bold;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 0;
        }
      }
    }

    &:hover {
      .product-name,
      .info,
      .time-stamp,
      .price,
      h3 {
        color: map-get($theme-colors, 'grey');
      }

      text-decoration: none;
    }
  }
}

.b2b .product-list {
  a div:last-child {
    margin-top: 15px;
  }

  .img-fluid {
    width: 260px;
    height: 260px;
    object-fit: contain;
  }
}
