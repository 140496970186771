﻿.c-videoSection {
    padding-top: 40px;
    padding-bottom: 40px;

    .video-area {
        position: relative;
        height: 300px;

        @include media-breakpoint-up (md) {
            height: 500px;
        }
    }

    .overlay {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        z-index: 3;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        text-align: center;
        color: map-get($theme-colors, 'beige');

        .video-content {
            position: relative;
            z-index: 2;
        }

        .play-btn {
            z-index: 10;
            background: none;
            border: none;
            cursor: pointer;
            transition: opacity .5s ease;

            svg {
                height: 50px;
                width: 50px;

                path {
                    fill: map-get($theme-colors, 'beige');
                }
            }

            &:hover {
                opacity: 0.8;
            }
        }

        &:after {
            content: "";
            width: 100%;
            height: 100%;
            background: linear-gradient( 180deg,rgba(0,0,0,.7) 8%,rgba(0,0,0,.2) 97%);
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .embed-container {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}
