﻿.c-newsList {
    a {
        @media (min-width: 768px) {
            padding: 15px 30px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
            &:nth-child(even) {
                padding-right: 15px;
            }

            &:nth-child(odd) {
                padding-left: 15px;
            }
        }

        @media (min-width: 992px) {
            &:nth-child(3n+3) {
                padding-right: 15px;
            }

            &:nth-child(3n + 4),
            &:first-of-type {
                padding-left: 15px;
            }
        }
    }
}

.c-News {
    &__image {
        margin-top: 10px;
        margin-bottom: 40px;
        height: 186px;
        width: 100%;
        position: relative;

        @media (min-width: 768px) {
            height: 386px;
        }

        @media (min-width: 992px) {
            height: 469px;
        }

        @media (min-width: 1199.98px) {
            height: 669px;
        }

        img {
            height: 100%;
            max-width: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    &__text {
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            max-width: 675px;
            margin: auto;
        }

    }
}

.contentText {
    max-width: 675px;
}
