﻿.osolo-nsg .sticky-top.b2b-header.open-menu {
  transition: none;
  background: map-get($theme-colors, 'darkblue');
}

.b2b-header {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .nav-item.dropdown {
        position: unset;

        > .dropdown-menu {
            > ul {
                max-height: 70vh;
                overflow-y: auto;
                padding: 20px;
                flex-direction: column;
                flex-wrap: wrap;
                width: 100vw;

                @media screen and (min-width: 1400px) {
                    max-height: 60vh;
                    padding: 50px 100px 50px 72px;
                }

                ul {
                    max-height: 70vh;
                    height: 70vh;
                    overflow-y: auto;
                    background: map-get($theme-colors, 'darkblue');
                    padding-bottom: 50px;


                    @include media-breakpoint-up(xl) {
                        max-height: auto;
                        height: auto;
                        overflow-y: visible;
                    }
                }
            }
        }
    }

    .select-language {
        @include media-breakpoint-up(xl) {
            display: none !important;
        }
    }

    .navbar {
        left: 32.5px !important;
    }

    .navbar-nav {
        > ul > li {
            margin-right: 0 !important;

            > a {
                padding-right: 13.5px !important;
                padding-left: 13.5px !important;

                &:focus,
                &:active {
                    text-decoration: none !important;
                }
            }
        }
    }

    @media (min-width: 1200px) and (max-width: 1329px) {
        .b2b-menu .navbar-nav {
            .nav-item.dropdown {
                &:hover {
                    > .dropdown-menu {
                        left: -146px;
                    }
                }
            }
        }
    }

    @media (min-width: 1330px) {
        .b2b-menu .navbar-nav {
            .nav-item.dropdown {
                &:hover {
                    > .dropdown-menu {
                        left: -198px;
                    }
                }
            }
        }
    }

    @media (min-width: 1200px) {
        .b2b-menu .navbar-nav {
            .go-back,
            .mobile-parent {
                display: none;
            }

            .nav-item.dropdown {
                position: unset;

                &:hover {
                    > a.nav-link {
                        height: 42px;
                        margin-bottom: -30px;
                    }

                    > .dropdown-menu > ul {
                        display: flex;
                    }
                }

                > .dropdown-menu {
                    > ul {
                        > li {
                            margin-bottom: 20px;

                            > a {
                                font-weight: bold;
                            }
                        }
                    }
                }

                .dropdown-menu {
                    .dropdown-menu {
                        position: relative;
                        display: contents;
                    }
                }
            }
        }
    }

    @media (max-width: 1199px) {
        
        .b2b-menu .main-menu {
            width: 90% !important;
            height: calc(100% - 170px) !important;

            .navbar-nav {
                overflow-y: auto;

                .nav-item {
                    flex-wrap: nowrap;
                    margin: 10px 0;

                    &.small {
                        margin-bottom: 7px;

                        a {
                            font-size: 18px;
                            line-height: 20px;
                            font-family: $font-main;
                        }
                    }

                    &.dropdown {
                        position: unset;
                    }
                }

                li {
                    .dropdown-arrow {
                        transform: rotate(135deg);
                        margin-top: 0;
                        flex-shrink: 0;
                    }

                    .open-sub-menu {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 50px;
                        right: -11px;
                        margin-top: 0;
                        padding-top: 0;

                        .go-back {
                            position: absolute;
                            left: 8px;
                            top: -45px;
                            background-color: map-get($theme-colors, 'darkblue');
                            cursor: pointer;

                            .left-arrow-back {
                                border: solid map-get($theme-colors, 'beige');
                                border-width: 0 2px 2px 0;
                                display: inline-block;
                                transform: rotate(135deg);
                                padding: 5px;
                                margin-right: 5px;
                            }
                        }
                    }

                    li.has-children {
                        font-size: 24px;
                        position: relative;
                        margin-bottom: 13px;

                        &.opened-menu {
                            position: unset;
                        }

                        .right-arrow-menu {
                            border: solid map-get($theme-colors, 'beige');
                            border-width: 0 2px 2px 0;
                            display: inline-block;
                            transform: rotate(-45deg);
                            padding: 5px;
                            margin-left: 5px;
                            margin-bottom: -1px;
                            position: absolute;
                            right: 0;
                            top: 12px;
                        }
                    }
                }
            }
        }
    }
}
