﻿/**
* Fonts
*/
$font-main: 'TTCommons-regular', sans-serif;
$font-bold: 'TTCommons-bold', sans-serif;

/**
 * Bootstrap
 * Here you can specify your own grid sizes and theme colors
 */
$theme-colors: (
  'primary': #1b2734,
  'gold' : #ffbe63,
  'lightgold' : #f4d4a5,
  'darkblue' : #1b2734,
  'black': #000000,
  'white' : #ffffff,
  'brown' : #b7a7a0,
  'beige' : #f1e9d6,
  'linen' : #f6f1e4,
  'darkbeige' : #baa69e,
  'nsg-blue'  : #24374e,
  'grey'  : #49525d,
  'softBrown' : #b6a7a0,
);
