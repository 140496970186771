/* Set padding to keep content from hitting the edges */
.body-content {
  padding-left: 15px;
  padding-right: 15px;
}

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
  max-width: 280px;
}

.overflow-hidden {
  overflow-x: hidden;
}

.full-width {
  width: 100%;
}

::selection {
  background-color: rgba(map-get($theme-colors, 'brown'), 0.7);
  color: #fff;
}

.b2b {
  overflow-x: hidden;
}

.b2b #addressForm .form-group input {
  max-width: none;
}

.nowrap {
  white-space: nowrap;
}
