.c-selectLanguage {
	display: none;
	border-right: 1px solid #f1e9d6;
	padding-right: 20px;
	margin-right: 20px;

	@include media-breakpoint-up (xl) {
		display: block;
	}

	a {
		font-size: 16px !important;

		&::after {
			content: none;
		}

		&:hover {
			text-decoration: none;
			opacity: 0.8;
		}

		&:focus {
			text-decoration: none;
		}
	}

	> a {
		display: flex;
		align-items: center;
		color: #f1e9d6;

		svg {
			margin-left: 5px;
		}
	}

	ul {
		padding: 0;

		a {
			display: block;
			color: #1b2734;
			padding: 10px 20px;
		}
	}
}
