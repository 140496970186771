﻿.theme-sdr.osolo-nsg .btn-success, .theme-sdr.osolo-nsg .btn-primary {
    color: map-get($theme-colors, 'beige');
    background: map-get($theme-colors, 'darkblue');
    border-color: map-get($theme-colors, 'darkblue');
    text-transform: unset;
    letter-spacing: 0.1rem;
    border-radius: 40px;

    &.btn-success:not(:disabled):not(.disabled):active,
    &.btn-success:not(:disabled):not(.disabled).active,
    .show > .btn-success.dropdown-toggle {
        color: map-get($theme-colors, 'beige');
        background: map-get($theme-colors, 'darkblue');
        border-color: map-get($theme-colors, 'darkblue');
    }
}

.theme-sdr.osolo-nsg .btn-outline-secondary {
    text-transform: unset;
    letter-spacing: 0.1rem;
    border-radius: 40px;
    display: flex;
    align-items: center;

    svg {
        display: block;
    }

    &.update-basket {
        span  {
            top: 0px;
        }
    }
}

.theme-sdr.osolo-nsg {
    #basket,
    .preview {
        a {
            color: map-get($theme-colors, 'darkblue');
            text-decoration: underline;
        }
    }

    .table {
        color: map-get($theme-colors, 'darkblue');

        td {
            border-color: map-get($theme-colors, 'softBrown');
        }

        thead {
            th {
                border-color: map-get($theme-colors, 'softBrown');
            }
        }
    }

    .table-striped tbody tr:nth-of-type(odd) {
        background-color: map-get($theme-colors, 'beige');
    }

    .table-striped tr:nth-last-of-type(odd) {
        background-color: map-get($theme-colors, 'white');
    }

    .checkout-form {
        min-height: 40vh;

        .page-footer {
            margin-top: 100px;
        }
    }
}

.theme-sdr.osolo-nsg .help-inline {
    background: map-get($theme-colors, 'gold');
}

.theme-sdr.osolo-nsg .alert-info {
    background: map-get($theme-colors, 'beige');
    color: map-get($theme-colors, 'darkblue');

    .btn {
        background: map-get($theme-colors, 'darkblue');
        color: map-get($theme-colors, 'beige');
        border-color: map-get($theme-colors, 'darkblue');
    }
}

.theme-sdr.osolo-nsg {
    .bg-beige {
        background: map-get($theme-colors, 'beige');
    }

    .form-group {
        input,
        select {
            border-radius: 15px;
            font-family: $font-main;
        }
    }

    .form-check-label-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            &:checked ~ .checkmark {
                background-color: map-get($theme-colors, 'white');

                &::after {
                    display: block;
                }
            }
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: map-get($theme-colors, 'white');
            border: 3px solid map-get($theme-colors, 'darkblue');
            border-radius: 10px;

            &::after {
                content: "";
                position: absolute;
                display: none;
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid map-get($theme-colors, 'darkblue');
                border-width: 0 3px 3px 0;
                transform: rotate(45deg);
            }
        }

        div {
            p {
                max-width: 700px;
            }

            img {
                max-width: 220px;
            }
        }
    }

    .input-append {
        input {
            display: inline-block;
        }
    }
}
