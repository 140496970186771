﻿#product-details {
    margin-top: 40px;
}

.product-information {
    @include media-breakpoint-down (lg) {
        order: 2;
    }

    a {
        text-decoration: underline;
        color: map-get($theme-colors, 'darkblue') !important;

        span {
            color: map-get($theme-colors, 'darkblue') !important;
        }

        &:hover {
            text-decoration: none;
            color: map-get($theme-colors, 'softBrown');

            span {
                text-decoration: none;
                color: map-get($theme-colors, 'softBrown') !important;
            }
        }
    }

    h1 {
        font-size: 42px;
        line-height: 42px;

        @include media-breakpoint-up (lg) {
            font-size: 50px;
            line-height: 50px;
        }
    }

    span {
        font-size: 16px;
        line-height: 20px;
    }

    .extra-info {
        margin-bottom: 28px;
        display: block;
    }

    .item-price {
        font-family: $font-bold;
        display: inline-block;
        margin-right: 15px;
        font-size: 30px;
        line-height: 32px;

        @include media-breakpoint-up (lg) {
            font-size: 32px;
            line-height: 34px;
        }
    }

    .gold-storage {
        display: flex;
        align-items: flex-start;
        margin-top: 20px;
        margin-bottom: 20px;

        input {
            margin-top: 8px;
            margin-right: 8px;
        }

        label {
            font-size: 16px;
            line-height: 20px;

            a {
                text-decoration: underline;
                color: map-get($theme-colors, 'darkblue');
            }
        }
    }

    .purchase_amount {
        display: flex;
        align-items: center;
        margin-top: 20px;
        flex-wrap: wrap;

        input[type=number] {
            height: 39px;
            border-radius: 30px;
            padding: 10px;
            border: 1px solid map-get($theme-colors, 'darkblue');
            width: 60px;
            text-align: center;
        }

        .alert-success {
            margin-top: 10px;
            background: map-get($theme-colors, 'beige');
            color: map-get($theme-colors, 'darkblue');
            font-family: $font-bold;

            .close {
                display: none;
            }
        }

        button {
            min-width: 70px;
            width: 176px;
            border-radius: 40px;
            margin-left: 15px;
            text-transform: initial;
            color: map-get($theme-colors, 'beige');

            &.close {
                width: unset;
                min-width: unset;
            }

            &:hover,
            &:focus,
            &:active {
                color: map-get($theme-colors, 'beige');
            }
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.gallery-thumbs {
    .slick-track {
        justify-content: center;
    }
}

.product-slider {
    height: 300px;

    @include media-breakpoint-up (lg) {
        height: 400px;
    }

    &.slick-slider .slick-track,
    &.slick-slider .slick-list {
        height: 100%;
    }

    .slick-slide {
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            img {
                max-height: 100%;
            }
        }
    }
}

.relatedImage {
    max-width: 387px;
    max-height: 387px;
}

.shipping-info-wrapper {
    background: linear-gradient( 130deg, #f1e9d6 0%, #1b2734 26%, #1b2734 51%, #f1e9d6 100%);
    padding: 50px;
    color: #f1e9d6;
    margin-top: 50px;
    margin-bottom: 50px;

    @include media-breakpoint-down (lg) {
        padding: 50px 20px;

        .shipping-info:first-of-type {
            margin-bottom:50px;
        }
    }

    @include media-breakpoint-up (lg) {
        .shipping-info {
            padding-left: 20px;

            &:first-of-type {
                padding-right: 20px;
                padding-left: 0px;
            }
        }
    }
}
