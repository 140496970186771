﻿.c-gridSection {
    .grid-section {
        > div {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        a {
            color: map-get($theme-colors, 'nsg-blue');
            text-decoration: underline;
        }

        a:hover {
            text-decoration: none;
            opacity: 0.8;
        }

        blockquote {
            border-color: map-get($theme-colors, 'brown');
            color: map-get($theme-colors, 'brown');

            &:before {
                color: map-get($theme-colors, 'brown');
                font-family: $font-bold;
                content: "";
                background-image: url("/assets/images/quoteBrown.svg");
                background-repeat: no-repeat;
                background-size: 30px;
                background-position: center;
            }
        }

        img {
            margin-top: 10px;
            margin-bottom: 20px;
        }

        p,
        li,
        a,
        h2,
        h3,
        h4,
        h5,
        h6,
        .withinGrid {
            margin-left: auto;
            margin-right: auto;
            max-width: 670px;
        }
    }
}
