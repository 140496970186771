@import url('https://fonts.googleapis.com/css?family=Prompt:300,400,500,600,700|Roboto:400,500&display=swap');

/*LEFT SIDE MENU (INCL ACCORDIAN)*/
.leftMenuStyle {
  padding-top: 15px;
  padding-bottom: 15px;
}

#accordian {
  background: #1b2734;
  width: 250px;
  color: white;
  box-shadow: 0 5px 15px 1px rgba(0, 0, 0, 0.6),
    0 0 200px 1px rgba(255, 255, 255, 0.5);
  margin-top: 30px;
}

i {
  margin-right: 10px;
}

#accordian li {
  list-style-type: none;
}

#accordian a,
#accordian a:hover,
#accordian {
  color: white !important;
  text-decoration: none;
  font-size: 13px;
  line-height: 22px;
  /* display: block;*/
  padding-left: 0px;
  padding-right: 15px;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
  position: relative;
}

#accordian a:hover {
  background: #003545;
  border-left: 5px solid lightgreen;
}

#accordian ul {
  padding-left: 10px;
}

#accordian ul ul {
  display: none;
}

#accordian li.active > ul {
  display: block;
}

#accordian ul ul ul {
  margin-left: 15px;
  border-left: 1px dotted rgba(0, 0, 0, 0.5);
}

#accordian a:not(:only-child):after {
  content: '\f107';
  font-family: fontawesome;
  position: unset;
  float: right;
  right: 10px;
  top: 0;
  font-size: 14px;
}

#accordian .active-menu-item > a:not(:only-child):after {
  content: '\f104';
}

.theme-sdr .bg-dark {
  background-color: #1b2734 !important;
}

.theme-sdr .bg-black {
  background-color: #1b2734;
}

.theme-sdr .bg-light {
  background-color: #ededed !important;
}

.theme-sdr .bg-primary {
  background-color: #ff9400 !important;
}

.theme-sdr .bg-secondary {
  background-color: #70a996 !important;
}

.theme-sdr .bg-success {
  background-color: #70a996 !important;
}

.theme-sdr .bg-info {
  background-color: #ffeacc !important;
}

.theme-sdr .text-danger {
  color: #c10000;
}

.theme-sdr a {
    color: #ffbe63;
}

.theme-sdr a:hover,
.theme-sdr a:active,
.theme-sdr a:focus {
  text-decoration: underline;
}

@media (max-width: 1199px) {
  .theme-sdr .container {
    max-width: 100%;
  }
}

.theme-sdr .shadow {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) !important;
}

@font-face {
  font-family: 'baron_neue';
  src: url('fonts/baronneue-regular-webfont.woff2') format('woff2'),
    url('fonts/baronneue-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'baron_neue';
  src: url('fonts/baronneue-black-webfont.woff2') format('woff2'),
    url('fonts/baronneue-black-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'baron_neue';
  src: url('fonts/baronneue-blackitalic-webfont.woff2') format('woff2'),
    url('fonts/baronneue-blackitalic-webfont.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'baron_neue';
  src: url('fonts/baronneue-bold-webfont.woff2') format('woff2'),
    url('fonts/baronneue-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'baron_neue';
  src: url('fonts/baronneue-bolditalic-webfont.woff2') format('woff2'),
    url('fonts/baronneue-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'baron_neue';
  src: url('fonts/baronneue-italic-webfont.woff2') format('woff2'),
    url('fonts/baronneue-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

body.theme-sdr {
  font-family: 'Avenir Next', 'Prompt', sans-serif;
  color: #1b2734 !important;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'kern' 1;
}

.theme-sdr h1,
.theme-sdr h2,
.theme-sdr h3,
.theme-sdr h4,
.theme-sdr h5 {
  font-family: 'baron_neue', sans-serif;
  font-weight: 400;
  letter-spacing: 0.125rem;
}

@media (min-width: 992px) {
  .theme-sdr h1,
  .theme-sdr h2,
  .theme-sdr h3,
  .theme-sdr h4,
  .theme-sdr h5 {
    letter-spacing: 0.2rem;
  }
}

.theme-sdr h6 {
  font-family: 'Avenir Next', 'Prompt', sans-serif;
}

.theme-sdr strong {
  font-weight: 600;
}

.theme-sdr .small {
  line-height: 1.3;
}

.theme-sdr .text-primary {
  color: #ff9400 !important;
}

.text-spaced {
  letter-spacing: 0.1rem;
}

.no-max-width {
  max-width: none !important;
}

.no-border {
  border: none !important;
}

.rotate-180 {
  transform: rotate(180deg);
  transform-origin: center;
}

.transform-from-bottom {
  transform-origin: bottom;
}

.stick-below-header {
  top: 3.75rem;
}

.theme-sdr img {
  max-width: 100%;
}

.theme-sdr svg {
  fill: currentColor;
}


.theme-sdr .header-container {
  background: rgba(255, 255, 255, 0.9);
  flex-shrink: 0;
  transition: background 200ms ease;
}


.theme-sdr .header-container header {
  min-height: 3.75rem;
}


@media (min-width: 576px) {
  .theme-sdr .navbar-brand {
    min-width: 14rem;
  }
}

@media (min-width: 768px) {
  .theme-sdr .navbar-brand {
    min-width: 15rem;
  }
}

.theme-sdr .navbar-toggler {
  outline: none;
}

.theme-sdr .navbar-toggler:hover,
.theme-sdr .navbar-toggler:active,
.theme-sdr .navbar-toggler:focus {
  color: #526e65;
}

.theme-sdr .navbar-toggler[aria-expanded='true'] {
  color: #70a996;
}

.theme-sdr .navbar-toggler[data-target='#navbar-form'] {
  background: #f7f7f7;
}

.theme-sdr .navbar-toggler[data-target='#navbar-form']:hover,
.theme-sdr .navbar-toggler[data-target='#navbar-form']:active,
.theme-sdr .navbar-toggler[data-target='#navbar-form']:focus {
  background: #ededed;
}

.theme-sdr .site-nav {
  background: #ededed;
  height: 2.25rem;
  line-height: 2.25rem;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  text-align: center;
}

@media (min-width: 992px) {
  .theme-sdr .site-nav {
    background: transparent;
    height: auto;
    line-height: 1;
    position: static;
    text-align: left;
  }
}

.theme-sdr .site-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.theme-sdr .site-nav ul li {
  margin: 0;
  padding: 0;
}

.theme-sdr .site-nav a {
  color: currentColor;
  font-size: 0.86rem;
  font-weight: 600;
}

.theme-sdr .site-nav a:hover,
.theme-sdr .site-nav a:active,
.theme-sdr .site-nav a:focus {
  color: #000;
  outline: none;
}

.theme-sdr .site-nav a[aria-expanded='true'] {
  color: #1b2734;
  font-weight: 700;
  outline: none;
  text-decoration: none;
}

.theme-sdr .navbar-form {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 3.75rem;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .theme-sdr .navbar-form {
    background: transparent;
    display: block;
    position: static;
  }
}

.theme-sdr .navbar-form.show {
  box-shadow: 0 0.3rem 0.4rem rgba(0, 0, 0, 0.25);
}

@media (min-width: 992px) {
  .theme-sdr.scrolled .category-nav.show {
    top: 3.75rem;
  }
}

.theme-sdr .category-nav {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: all 200ms ease;
}

.theme-sdr .category-nav.collapsing {
  z-index: 10;
}

.theme-sdr .category-nav.show {
  opacity: 1;
  top: 6rem;
  z-index: 20;
}

.theme-sdr .navigation-dropdown-wrapper .container {
  position: relative;
}

.theme-sdr .navigation-dropdown-wrapper .close {
  color: #000;
  position: absolute;
  top: 0;
  right: 1.5rem;
}

.theme-sdr .navigation-dropdown-wrapper ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: 'Avenir Next', 'Prompt', sans-serif;
  font-size: 0.82rem;
}

.theme-sdr .navigation-dropdown-wrapper ul li {
  margin: 0;
  padding: 0;
}

.theme-sdr .navigation-dropdown-featured-item {
  flex: 0 0 100%;
}

@media (min-width: 576px) {
  .theme-sdr .navigation-dropdown-featured-item {
    flex: 0 0 auto;
  }
}

.theme-sdr .navigation-dropdown-item {
  flex: 0 0 auto;
}

.theme-sdr .navigation-dropdown-item a,
.theme-sdr .navigation-dropdown-featured-item a {
  color: currentColor;
  display: block;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  position: relative;
}

@media (min-width: 768px) {
  .theme-sdr .navigation-dropdown-item a,
  .theme-sdr .navigation-dropdown-featured-item a {
    font-weight: 300;
  }
}

.theme-sdr .navigation-dropdown-item a:hover,
.theme-sdr .navigation-dropdown-item a:active,
.theme-sdr .navigation-dropdown-item a:focus,
.theme-sdr .navigation-dropdown-featured-item a:hover,
.theme-sdr .navigation-dropdown-featured-item a:active,
.theme-sdr .navigation-dropdown-featured-item a:focus {
  outline: none;
  text-decoration: none;
}

.theme-sdr .navigation-dropdown-item a:hover::after,
.theme-sdr .navigation-dropdown-item a:active::after,
.theme-sdr .navigation-dropdown-item a:focus::after,
.theme-sdr .navigation-dropdown-featured-item a:hover::after,
.theme-sdr .navigation-dropdown-featured-item a:active::after,
.theme-sdr .navigation-dropdown-featured-item a:focus::after {
  opacity: 1;
  transform: translateX(0);
}

.theme-sdr .navigation-dropdown-item a::after,
.theme-sdr .navigation-dropdown-featured-item a::after {
  content: '>';
  display: inline-block;
  font-family: 'baron_neue', sans-serif;
  opacity: 0;
  transform: translateX(-0.5rem);
  transition: all 200ms ease;
}

.theme-sdr .confidences {
  display: flex;
  justify-content: space-between;
  height: 2.25rem;
}

.theme-sdr .confidences.slick-initialized .confidence-item {
  opacity: 1;
}

@media (min-width: 992px) {
  .theme-sdr .confidences.slick-initialized .slick-list {
    width: 100%;
  }
}

.theme-sdr .confidence-item {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 2.25rem;
  opacity: 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: all 200ms ease;
}

@media (min-width: 768px) {
  .theme-sdr .confidence-item {
    flex: 0 0 50%;
  }
}

@media (min-width: 992px) {
  .theme-sdr .confidence-item {
    flex: 0 0 auto;
    opacity: 1;
    line-height: 1;
    text-align: left;
  }
}

.theme-sdr .confidence-item .icon {
  width: 1.2rem;
  height: 1.2rem;
  margin-right: 0.5rem;
}

.theme-sdr .confidence-item .icon-truck {
  width: 1.6rem;
}

.theme-sdr .typeahead {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 0;
  border: none;
  margin-top: 0;
  padding: 0;
  position: fixed;
  max-width: 90%;
  z-index: 1020;
  top: 7rem !important;
}

@media (min-width: 992px) {
  .theme-sdr .typeahead {
    max-width: 25rem;
    top: 3.75rem !important;
  }
}

.theme-sdr .typeahead li {
  padding: 0;
}

.theme-sdr .typeahead .active a {
  background: #d3e0e6;
}

.theme-sdr .typeahead a {
  color: #000;
  display: block;
  overflow: hidden;
  padding: 0.5rem 1rem;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.theme-sdr .typeahead a:hover,
.theme-sdr .typeahead a:active,
.theme-sdr .typeahead a:focus {
  background: #d3e0e6;
}

.theme-sdr .site-footer {
  margin-top: 30px !important;
  font-size: 0.8rem;
  font-weight: 300;
  line-height: 1.8;
}

.theme-sdr .site-footer .row ~ .row {
  position: relative;
}

.theme-sdr .site-footer .row ~ .row::before {
  border-top: thin solid #fff;
  content: '';
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
}

.theme-sdr .site-footer h6 {
  font-weight: 500;
  letter-spacing: 0.01rem;
}

.theme-sdr .site-footer h6.text-smaller {
  font-size: 0.92rem;
}

.theme-sdr .site-footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.theme-sdr .site-footer ul li {
  margin: 0;
  padding: 0;
}

.theme-sdr .site-footer a {
  color: currentColor;
}

.theme-sdr .site-footer a:hover,
.theme-sdr .site-footer a:active,
.theme-sdr .site-footer a:focus {
  color: #dcdcdc;
}

.theme-sdr .site-footer .logo-ucommerce {
  height: 1.8rem;
}

.theme-sdr .site-footer .payment-options {
  height: 1.6rem;
}

@media (min-width: 768px) {
  .theme-sdr .site-footer .payment-options {
    height: 1.16rem;
  }
}

.theme-sdr .page-footer .btn {
  min-width: 0;
}

.theme-sdr .alert {
  border: 0;
  border-radius: 0;
  font-weight: 300;
}

.theme-sdr .alert p {
  line-height: 1.3;
}

.theme-sdr .alert p:last-child {
  margin-bottom: 0;
}

.theme-sdr .alert a {
  color: currentColor;
  font-weight: 400;
}

.theme-sdr .alert .close {
  color: currentColor;
  font-weight: 500;
  position: absolute;
  top: 0;
  right: 0.5rem;
  text-shadow: none;
}

.theme-sdr .alert-success {
  background: #70a996;
  color: #fff;
}

.theme-sdr .alert-info {
  background: #d3e0e6;
}

.theme-sdr .breadcrumb {
  margin: 0;
}

.theme-sdr .breadcrumb-item {
  align-items: flex-end;
  font-size: 0.82rem;
  font-weight: 500;
  line-height: 1;
}

.theme-sdr .breadcrumb-item:last-child {
  pointer-events: none;
}

.theme-sdr .breadcrumb-item:last-child::after {
  display: none;
}

.theme-sdr .breadcrumb-item::before {
  display: none;
}

.theme-sdr .breadcrumb-item::after {
  background: transparent url('/img/icons/chevron.svg') center no-repeat;
  background-size: 0.6rem;
  content: '';
  flex: 0 0 auto;
  height: 100%;
  width: 1.5rem;
  transform: rotate(-90deg);
}

.theme-sdr .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}

.theme-sdr .breadcrumb-item a {
  flex: 1;
  padding: 0.15rem 0;
  max-width: 21.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width: 768px) {
  .theme-sdr .breadcrumb-item a {
    max-width: 30rem;
  }
}

@media (min-width: 992px) {
  .theme-sdr .breadcrumb-item a {
    max-width: none;
  }
}

.theme-sdr .btn {
  border-radius: 0;
  border-width: 2px;
  font-family: 'Roboto', sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.6;
  min-width: 14.5rem;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .theme-sdr .btn {
    letter-spacing: 0.2rem;
  }
}

.theme-sdr .btn:hover,
.theme-sdr .btn:active,
.theme-sdr .btn:focus {
  box-shadow: none;
  text-decoration: none;
}

.theme-sdr .btn:disabled,
.theme-sdr .btn.disabled {
  font-weight: 400;
  opacity: 0.5;
  pointer-events: none;
}

@media (min-width: 768px) {
  .theme-sdr .btn:disabled,
  .theme-sdr .btn.disabled {
    font-weight: 300;
  }
}

.theme-sdr .btn.border-thin {
  border-width: 1px;
}

.theme-sdr .btn.hover-green:hover,
.theme-sdr .btn.hover-green:active,
.theme-sdr .btn.hover-green:focus {
  background: #70a996;
  border-color: #70a996;
  color: #fff;
}

.theme-sdr button .icon-active {
  display: none;
}

.theme-sdr button[aria-expanded='true'] .icon-active {
  display: block;
}

.theme-sdr button[aria-expanded='true'] .icon-default {
  display: none;
}

.theme-sdr .btn-sm {
  line-height: 1;
  min-width: 0;
  padding: 0.25rem 0.5rem;
}

@media (min-width: 768px) {
  .theme-sdr .btn-sm {
    letter-spacing: 0.1rem;
  }
}

.theme-sdr .text-dark {
  color: #1b2734 !important;
}

.theme-sdr .btn-dark {
  color: #fff;
  background: #1b2734;
  border-color: #1b2734;
}

.theme-sdr .btn-dark:hover,
.theme-sdr .btn-dark:active,
.theme-sdr .btn-dark:focus {
  color: #fff;
  background: #000;
  border-color: #000;
}

.theme-sdr .btn-outline-dark {
  color: #1b2734;
  border-color: #1b2734;
}

.theme-sdr .btn-outline-dark:hover,
.theme-sdr .btn-outline-dark:active,
.theme-sdr .btn-outline-dark:focus {
  color: #fff;
  background: #1b2734;
  border-color: #1b2734;
}

.theme-sdr .btn-outline-secondary {
  color: #666;
  border-color: #666;
}

.theme-sdr .btn-outline-secondary:hover,
.theme-sdr .btn-outline-secondary:active,
.theme-sdr .btn-outline-secondary:focus {
  color: #fff;
  background: #666;
  border-color: #666;
}

.theme-sdr .btn-outline-light {
  color: #fff;
  border-color: #fff;
  border-width: 2px;
}

.theme-sdr .btn-outline-light:hover,
.theme-sdr .btn-outline-light:active,
.theme-sdr .btn-outline-light:focus {
  color: #000;
  background: #fff;
  border-color: #fff;
}

.theme-sdr .btn-outline-secondary {
  color: #666;
  border-color: #666;
}

.theme-sdr .btn-outline-secondary:hover,
.theme-sdr .btn-outline-secondary:active,
.theme-sdr .btn-outline-secondary:focus {
  color: #fff;
  background: #666;
  border-color: #666;
}

.theme-sdr .btn-success,
.theme-sdr .btn-primary {
  color: #fff;
  background: #70a996;
  border-color: #70a996;
}

.theme-sdr .btn-success:hover,
.theme-sdr .btn-success:active,
.theme-sdr .btn-success:focus,
.theme-sdr .btn-primary:hover,
.theme-sdr .btn-primary:active,
.theme-sdr .btn-primary:focus {
  color: #fff;
  background: #5c9985;
  border-color: #5c9985;
}

.theme-sdr .btn-accordion {
  background: #fff;
  color: #1b2734;
  border: 1px solid #1b2734;
  border-width: 0 0 1px;
  display: flex;
  justify-content: space-between;
  font-family: 'Avenir Next', 'Prompt', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  outline: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}

@media (min-width: 768px) {
  .theme-sdr .btn-accordion {
    letter-spacing: 0.05rem;
  }
}

.theme-sdr .btn-accordion .icon {
  transition: transform 200ms ease;
  transform-origin: center;
}

.theme-sdr .btn-accordion:first-of-type {
  border-top-width: 1px;
}

.theme-sdr .btn-accordion:hover,
.theme-sdr .btn-accordion[aria-expanded='true'] {
  color: #fff;
  background: #000;
  border-color: #000;
}

.theme-sdr .btn-accordion[aria-expanded='true'] .icon {
  transform: rotate(180deg) translateY(-0.5rem);
}

.theme-sdr .btn-link {
  border: 0;
  color: #70a996;
}

.theme-sdr .btn-link.line-remove {
  color: currentColor;
  text-decoration: none;
}

.theme-sdr .btn-link.line-remove:hover,
.theme-sdr .btn-link.line-remove:active,
.theme-sdr .btn-link.line-remove:focus {
  color: #c10000;
}

.theme-sdr .btn-hide-expanded[aria-expanded='true'] {
  display: none;
}

.theme-sdr .btn-search {
  background: transparent;
  border: 0;
}

.theme-sdr .btn-search:hover,
.theme-sdr .btn-search:active,
.theme-sdr .btn-search:focus {
  color: #70a996;
}

.theme-sdr .searching .btn-search {
  pointer-events: none;
}

.theme-sdr .searching .btn-search .icon-active {
  display: block;
}

.theme-sdr .searching .btn-search .icon-default {
  display: none;
}

.theme-sdr .card {
  border-radius: 0;
  border: thin solid #000;
}

.theme-sdr .carousel {
  position: relative;
}

.theme-sdr .carousel picture {
  position: relative;
  overflow: hidden;
  max-height: 40rem;
  width: 100%;
}

.theme-sdr .carousel picture img {
  width: 100%;
}

.theme-sdr .carousel .btn {
  min-width: 0;
  transform: scale(0.8);
  transform-origin: top left;
}

@media (min-width: 768px) {
  .theme-sdr .carousel .btn {
    min-width: 14.5rem;
    transform: scale(1);
  }
}

.theme-sdr .carousel-caption {
  top: auto;
  bottom: 3rem;
  left: 7vw;
  margin: auto 0;
  padding: 0;
  width: 45vw;
  text-align: left;
}

@media (min-width: 768px) {
  .theme-sdr .carousel-caption {
    right: auto;
    bottom: 5rem;
    left: 7vw;
    width: 40vw;
    text-align: left;
  }
}

.theme-sdr .carousel-caption h1,
.theme-sdr .carousel-caption h2,
.theme-sdr .carousel-caption h3,
.theme-sdr .carousel-caption h4,
.theme-sdr .carousel-caption h5,
.theme-sdr .carousel-caption h6 {
  font-size: 5vw;
  letter-spacing: 0;
}

@media (min-width: 768px) {
  .theme-sdr .carousel-caption h1,
  .theme-sdr .carousel-caption h2,
  .theme-sdr .carousel-caption h3,
  .theme-sdr .carousel-caption h4,
  .theme-sdr .carousel-caption h5,
  .theme-sdr .carousel-caption h6 {
    font-size: 4vw;
    letter-spacing: 0.25rem;
  }
}

.theme-sdr .carousel-indicators {
  right: auto;
  bottom: 0.25rem;
  left: 7vw;
  margin: 0;
}

@media (min-width: 768px) {
  .theme-sdr .carousel-indicators {
    bottom: 0.5rem;
  }
}

.theme-sdr .carousel-indicators li {
  cursor: pointer;
  height: 5px;
  min-width: 15px;
  margin: 0 3px 0 0;
  opacity: 0.36;
  transition: all 200ms ease;
}

@media (min-width: 768px) {
  .theme-sdr .carousel-indicators li {
    height: 9px;
    min-width: 34px;
  }
}

.theme-sdr .carousel-indicators li:hover,
.theme-sdr .carousel-indicators li:focus {
  opacity: 1;
}

.theme-sdr .carousel-indicators li.active,
.theme-sdr .carousel-indicators li.slick-active {
  background-color: #000;
  opacity: 1;
}

.theme-sdr .multi-item-carousel.product-list .slick-slide a {
  max-width: 100%;
}

.theme-sdr .multi-item-carousel .carousel-item {
  display: block;
  width: auto;
  float: none;
  margin: 0;
}

.theme-sdr .multi-item-carousel .carousel-indicators {
  right: 0;
  bottom: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.theme-sdr .multi-item-carousel .carousel-indicators li {
  background-color: #ededed;
  opacity: 1;
}

.theme-sdr .multi-item-carousel .carousel-indicators li:hover,
.theme-sdr .multi-item-carousel .carousel-indicators li:focus {
  background-color: #dbdbdb;
}

.theme-sdr .multi-item-carousel .carousel-indicators li.active {
  background-color: #000;
}

.theme-sdr .multi-item-carousel .slick-dots {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  width: 100%;
}

.theme-sdr .multi-item-carousel .slick-dots.slick-dots li {
  cursor: pointer;
  height: 5px;
  min-width: 15px;
  margin: 0 3px 0 0;
  opacity: 0.36;
  transition: all 200ms ease;
  opacity: 0.1;
}

@media (min-width: 768px) {
  .theme-sdr .multi-item-carousel .slick-dots.slick-dots li {
    height: 9px;
    min-width: 34px;
  }
}

.theme-sdr .multi-item-carousel .slick-dots.slick-dots li:hover,
.theme-sdr .multi-item-carousel .slick-dots.slick-dots li:focus {
  opacity: 1;
}

.theme-sdr .multi-item-carousel .slick-dots.slick-dots li.active,
.theme-sdr .multi-item-carousel .slick-dots.slick-dots li.slick-active {
  background-color: #000;
  opacity: 1;
}

.theme-sdr .multi-item-carousel .slick-dots li {
  background: #000;
  position: relative;
}

.theme-sdr .multi-item-carousel .slick-dots button {
  background: transparent;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-indent: -200vw;
  width: 100%;
}

.theme-sdr .h4 .caption {
  bottom: 1rem;
}

@media (min-width: 768px) {
  .theme-sdr .h4 .caption {
    bottom: 1.5rem;
  }
}

.theme-sdr .image-link {
  position: relative;
}

.theme-sdr .image-link:hover,
.theme-sdr .image-link:active,
.theme-sdr .image-link:focus {
  outline: none;
}

.theme-sdr .image-link:hover img,
.theme-sdr .image-link:active img,
.theme-sdr .image-link:focus img {
  filter: brightness(1.1);
}

.theme-sdr .image-link img {
  transition: all 200ms ease;
  width: 100%;
}

.theme-sdr .caption {
  color: #000;
  font-family: 'baron_neue', sans-serif;
  font-size: inherit;
  line-height: 1.2;
  margin: 0 auto;
  padding: 0.75rem 0.5rem;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  text-align: center;
  text-shadow: 0px 0px 3px #fff, 0px 0px 5px rgba(255, 255, 255, 0.5),
    0px 0px 10px rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .theme-sdr .caption {
    letter-spacing: 0.125rem;
    padding: 1rem;
  }
}

@media (min-width: 992px) {
  .theme-sdr .caption {
    letter-spacing: 0.25rem;
  }
}

.theme-sdr .filterbar .dropdown {
  display: inline-flex;
  align-items: flex-end;
  margin-right: 0.25rem;
}

.theme-sdr .filterbar .dropdown:last-child {
  margin-right: 0;
}

.theme-sdr .filterbar .dropdown.open .dropdown-toggle {
  background-color: #fff;
  border-color: #000;
  border-bottom-color: #fff;
  z-index: 11;
}

.theme-sdr .filterbar .dropdown.open .dropdown-menu {
  display: block;
  z-index: 10;
}

.theme-sdr .filterbar .dropdown-toggle {
  color: #1b2734;
  background: #fff;
  border: thin solid #fff;
  font-weight: 500;
  outline: none;
  padding: 0.5rem 2rem;
  position: relative;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .theme-sdr .filterbar .dropdown-toggle {
    padding: 1rem 2rem;
  }
}

.theme-sdr .filterbar .dropdown-toggle:active {
  border-color: #fff;
}

.theme-sdr .filterbar .dropdown-toggle:hover,
.theme-sdr .filterbar .dropdown-toggle:focus {
  background-color: #eee;
}

.theme-sdr .filterbar .dropdown-toggle::after {
  display: none;
}

.theme-sdr .filterbar .dropdown-menu {
  border-radius: 0;
  border: thin solid #000;
  margin-top: -1px;
  min-width: 14rem;
  padding: 1.5rem 1rem;
  transition: all 200ms ease;
}

.theme-sdr .filterbar select {
  color: #1b2734;
  border: thin solid #fff;
  margin-right: 0.5rem;
  padding: 0.5rem 3rem 0.5rem 1rem;
  text-indent:initial;
}

@media (min-width: 768px) {
  .theme-sdr .filterbar select {
    padding: 1rem 3rem 1rem 1rem;
  }
}

.theme-sdr .filterbar label {
  white-space: nowrap;
}

.theme-sdr .gallery-large {
  list-style: none;
  margin: 0;
  padding: 0;
}

.theme-sdr .gallery-large li {
  margin: 0;
  padding: 0;
}

.theme-sdr .gallery-large img {
  width: auto !important;
}

.theme-sdr .gallery-thumbs .slick-track {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.25rem;
  padding: 0.5rem 0;
  width: calc(100% + 0.5rem) !important;
}

.theme-sdr .gallery-thumbs .slick-slide {
  flex: 0 0 calc(20% - 0.5rem);
  margin: 0 0.25rem 0.5rem;
  width: calc(20% - 0.5rem) !important;
}

.theme-sdr .gallery-thumbs .slick-slide:hover,
.theme-sdr .gallery-thumbs .slick-slide:active,
.theme-sdr .gallery-thumbs .slick-slide:focus {
  border: 0;
}

.theme-sdr .gallery-thumbs .slick-slide:hover img,
.theme-sdr .gallery-thumbs .slick-slide:active img,
.theme-sdr .gallery-thumbs .slick-slide:focus img {
  filter: brightness(1.1);
  transform: scale(1.01);
}

.theme-sdr .gallery-thumbs img {
  cursor: pointer;
  transition: all 400ms ease;
}

.theme-sdr .hero-unit {
  position: relative;
  width: 100%;
}

.theme-sdr .hero-unit .container {
  display: flex;
  align-items: center;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.theme-sdr .hero-unit h1,
.theme-sdr .hero-unit h2,
.theme-sdr .hero-unit h3,
.theme-sdr .hero-unit h4,
.theme-sdr .hero-unit h5,
.theme-sdr .hero-unit h6 {
  font-size: 4vw;
  max-width: 40vw;
}

.theme-sdr .icon {
  display: inline-block;
  fill: currentColor;
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
}

.theme-sdr .icon svg {
  fill: currentColor;
  max-width: 100%;
  max-height: 100%;
}

.theme-sdr .icon-small {
  height: 1.2rem;
  width: 1.2rem;
}

.theme-sdr .icon-xs {
  height: 0.8rem;
  width: 0.8rem;
}

.theme-sdr .icon-up {
  transform: translateY(-0.1rem);
}

.theme-sdr .jumbotron {
  background-color: #f7f7f7;
}

.theme-sdr .jumbotron .container {
  display: flex;
  align-items: center;
  position: relative;
}

.theme-sdr .jumbotron .cms-content {
  background: url('/img/site/bg-pattern.png') top center no-repeat;
  background-size: cover;
  color: #fff;
  padding: 5rem 1rem 1rem;
}

@media (min-width: 768px) {
  .theme-sdr .jumbotron .cms-content {
    padding: 9rem 6rem 6rem;
  }
}

.theme-sdr .jumbotron .cms-content .container {
  display: block;
  position: static;
}

.theme-sdr .jumbotron h1 {
  font-size: 2.75rem;
  letter-spacing: 0;
  padding-bottom: 4rem;
  position: relative;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .theme-sdr .jumbotron h1 {
    font-size: 3.75rem;
  }
}

.theme-sdr .jumbotron h1::after {
  border-top: 1px solid #fff;
  content: '';
  width: 130vw;
  position: absolute;
  bottom: 2rem;
  left: -100vw;
}

.theme-sdr .jumbotron h6,
.theme-sdr .jumbotron p {
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.4;
  max-width: 40rem;
}

@media (min-width: 768px) {
  .theme-sdr .jumbotron h6,
  .theme-sdr .jumbotron p {
    font-weight: 300;
    font-size: 1.1rem;
  }
}

.theme-sdr .jt-1 {
  position: absolute;
  top: -1rem;
  width: auto;
  height: 120%;
}

@media (min-width: 768px) {
  .theme-sdr .jt-1 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: -2.5rem;
    margin-bottom: -5rem;
    position: relative;
    top: auto;
    height: auto;
    width: 28%;
  }
}

.theme-sdr .jt-2 {
  display: none;
}

@media (min-width: 768px) {
  .theme-sdr .jt-2 {
    display: block;
    flex: 0 0 40%;
    max-width: 40%;
    margin: 1.5rem 0 5rem -5rem;
    height: auto;
    position: relative;
  }
}

@media (min-width: 992px) {
  .theme-sdr .jt-2 {
    flex: 0 0 35%;
    max-width: 35%;
    margin-top: 1rem;
  }
}

.theme-sdr .jt-lead {
  background-color: #70a996;
  flex: 0 0 60%;
  align-self: flex-end;
  color: #fff;
  margin: 1rem 0 1rem auto;
  padding: 1.5rem 1.5rem 1rem;
  position: relative;
  z-index: 10;
}

@media (min-width: 576px) {
  .theme-sdr .jt-lead {
    flex: 0 0 66%;
  }
}

@media (min-width: 768px) {
  .theme-sdr .jt-lead {
    -ms-grid-row-align: center;
    align-self: center;
    margin: auto 0;
    position: absolute;
    right: 0;
    width: 30%;
    max-width: 24rem;
  }
}

.theme-sdr .jt-lead h6 {
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.25rem;
  margin-bottom: 2rem;
  position: relative;
  text-transform: uppercase;
}

.theme-sdr .jt-lead h6::after {
  border-top: 1px solid #fff;
  content: '';
  width: 8rem;
  position: absolute;
  bottom: -1rem;
  left: -3.5rem;
}

.theme-sdr .jt-lead p {
  font-size: 0.92rem;
}

.theme-sdr .pipe-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: bottom;
}

.theme-sdr .pipe-list li {
  margin: 0;
  padding: 0;
}

.theme-sdr .pipe-list li:not(:first-child) {
  position: relative;
}

.theme-sdr .pipe-list li:not(:first-child)::before {
  content: '|';
  padding: 0 0.4rem;
}

.theme-sdr .minibasket.minibasket {
  color: #1b2734;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 200ms ease;
}

.theme-sdr .minibasket.minibasket:hover,
.theme-sdr .minibasket.minibasket:active,
.theme-sdr .minibasket.minibasket:focus {
  outline-offset: 0;
  text-decoration: underline;
}

.theme-sdr .minibasket.has-items {
  color: #ff9400;
  position: relative;
}

@media (min-width: 768px) {
  .theme-sdr .minibasket.has-items:hover,
  .theme-sdr .minibasket.has-items:active,
  .theme-sdr .minibasket.has-items:focus {
    text-decoration: underline;
  }

  .theme-sdr .minibasket.has-items:hover .icon,
  .theme-sdr .minibasket.has-items:active .icon,
  .theme-sdr .minibasket.has-items:focus .icon {
    color: currentColor;
  }
}

.theme-sdr .minibasket.has-items .icon {
  color: #1b2734;
}

.theme-sdr .minibasket.has-items .item-qty {
    color: map-get($theme-colors, 'beige');
    font-weight: 600;
    line-height: 1;
    margin: 0;
    position: static;
    right: -40px;
    bottom: 0.1rem;
    left: 0;
    text-align: center;
}

@media (min-width: 768px) {
  .theme-sdr .minibasket.has-items .item-qty {
    color: currentColor;
    position: static;
    text-shadow: none;
  }
}

.theme-sdr .page-navigation h6 {
  font-size: 0.9rem;
  font-weight: 600;
}

.theme-sdr .pagination {
  border-radius: 0;
  margin: 0 auto;
}

.theme-sdr .page-item {
  text-indent: -100vw;
  overflow: hidden;
  outline: none;
}

.theme-sdr .page-item:first-child .page-link,
.theme-sdr .page-item:last-child .page-link {
  border-radius: 0;
}

.theme-sdr .active .page-link {
  background: #000;
  pointer-events: none;
}

.theme-sdr .active ~ .page-item .page-link {
  background: #dcdcdc;
}

.theme-sdr .active ~ .page-item .page-link:hover,
.theme-sdr .active ~ .page-item .page-link:active,
.theme-sdr .active ~ .page-item .page-link:focus {
  background: #b0b0b0;
}

.theme-sdr .page-link {
  background: #000;
  border: 0;
  padding: 0;
  height: 6px;
  width: 55px;
  transition: background 200ms ease;
}

.theme-sdr .page-link:hover,
.theme-sdr .page-link:active,
.theme-sdr .page-link:focus {
  background: #b0b0b0;
}

.theme-sdr .product-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.theme-sdr .product-list li {
  margin: 0;
  padding: 0;
}

/*.theme-sdr .product-list::after {
  content: '';
  display: block;
  flex: 1 1 auto;
}*/

@media (min-width: 576px) {
  .theme-sdr .product-list.keep-rows-even a {
    max-width: 25%;
  }
}

.theme-sdr .product-list a {
  color: currentColor;
  line-height: 1.2;
  padding: 0 2px;
  position: relative;
  text-decoration: none;
  transition: all 200ms ease;
  display: flex;
  flex-direction: column;
}

/*@media (min-width: 768px) {
  .theme-sdr .product-list a {
    max-width: 33%;
  }
}

@media (min-width: 992px) {
  .theme-sdr .product-list a {
    max-width: 25%;
  }
}*/

.theme-sdr .product-list a:hover .product-image img,
.theme-sdr .product-list a:active .product-image img,
.theme-sdr .product-list a:focus .product-image img {
    filter: brightness(1.075);
    transform: scale(1.01);
}

.theme-sdr .product-list a div:last-child {
  margin-top: auto;
}

.theme-sdr .product-list .product-image {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.theme-sdr .product-list .product-image img {
    margin: auto;
    display: block;
    transition: transform 400ms ease;
}

.theme-sdr .subcategories {
  margin-top: 30px;
  border: 1px solid #1b2734;
}

.theme-sdr .subcategories h3 {
  padding: 5px;
  color: white;
  font-size: 16px;
  background-color: #1b2734; /*rgba(0, 0, 0, 0);*/
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
}

.theme-sdr .category-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: 'baron_neue', sans-serif;
  font-size: 1.1rem;
  letter-spacing: 0;
  line-height: 1;
  min-width: 0;
  padding: 0.5rem;
}

.theme-sdr .category-list li {
  margin: 0;
  padding: 0;
}

.theme-sdr .category-list::after {
  content: '';
  display: block;
  flex: 1 1 auto;
}

@media (min-width: 576px) {
  .theme-sdr .category-list.keep-rows-even a {
    max-width: 25%;
  }
}

.theme-sdr .category-list a {
  color: currentColor;
  flex: 1 1 0;
  line-height: 1.2;
  max-width: 50%;
  min-width: 25%;
  padding: 0 2px;
  position: relative;
  text-decoration: none;
  text-align: center;
  transition: all 200ms ease;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .theme-sdr .category-list a {
    max-width: 33%;
  }
}

@media (min-width: 992px) {
  .theme-sdr .category-list a {
    max-width: 25%;
  }
}

.theme-sdr .category-list a:hover .category-image img,
.theme-sdr .category-list a:active .category-image img,
.theme-sdr .category-list a:focus .category-image img {
  filter: brightness(1.075);
  transform: scale(1.01);
}

.theme-sdr .category-list a div:last-child {
  margin-top: auto;
}

.theme-sdr .category-list .category-image {
  overflow: hidden;
}

.theme-sdr .category-list .category-image img {
  object-fit: cover;
  object-position: center;
  height: 200px;
  width: 100%;
  transition: all 400ms ease;
}

.theme-sdr .rating {
  display: flex;
  justify-content: flex-start;
}

.theme-sdr .rating:hover .fa-star-o::before,
.theme-sdr .rating:active .fa-star-o::before,
.theme-sdr .rating:focus .fa-star-o::before {
  content: '\f005';
}

.theme-sdr .rating label {
  cursor: pointer;
  position: relative;
}

.theme-sdr .rating label input {
  opacity: 0;
  position: absolute;
}

.theme-sdr .rating label.active .fa-star-o::before {
  content: '\f005';
}

.theme-sdr .rating label:hover ~ label .fa-star-o::before,
.theme-sdr .rating label:active ~ label .fa-star-o::before,
.theme-sdr .rating label:focus ~ label .fa-star-o::before {
  content: '\f006';
}

.theme-sdr .review {
  border-top: 1px solid #979797;
}

.theme-sdr .review:first-of-type {
  border-top: 0;
}

.theme-sdr .review h6 {
  font-family: 'baron_neue', sans-serif;
}

.theme-sdr .review p {
  font-weight: 300;
  line-height: 1.3;
}

.theme-sdr .review-header {
  font-size: 1.1rem;
}

.theme-sdr .review-header strong {
  font-weight: 600;
}

.theme-sdr .review-header .small {
  text-decoration: none;
}

.theme-sdr .load-more button[aria-expanded='true'] {
  display: none !important;
}

.theme-sdr .additional-reviews {
  border-top: 1px solid #979797;
}

.theme-sdr .table {
  max-width: 100%;
}

.theme-sdr .table td {
  border-color: #dcdcdc;
  font-size: 0.9rem;
}

@media (min-width: 768px) {
  .theme-sdr .table td {
    font-size: 1rem;
  }
}

.theme-sdr .table input {
  height: auto;
  padding: 0.25rem 0.5rem;
}

.theme-sdr .table .number .form-control {
  width: 3.5rem;
}

.theme-sdr thead th {
  background: #fff;
  border-color: #dcdcdc;
  border-top-width: 2px;
}

.theme-sdr .table-striped tr:nth-last-of-type(odd) {
  background-color: #f7f7f7;
}

.form-check-label {
  color: #000;
  cursor: pointer;
  display: block;
  font-family: 'Avenir Next', 'Prompt', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.1;
  margin: 0 0 0.5rem 1px;
  padding: 0.125rem 0 0 1.5rem;
  position: relative;
  text-align: left;
  text-transform: uppercase;
}

.form-check-label:hover .cb::after,
.form-check-label:active .cb::after,
.form-check-label:focus .cb::after {
  transform: scale(1);
}

.form-check-label.is-radio .cb::before {
  border-radius: 50%;
}

.form-check-label.is-radio .cb::after {
  background-position: top 0.25rem left 0.15rem;
}

.form-check-label.disabled {
  pointer-events: none;
  opacity: 0.5;
  font-weight: 400;
}

.form-check-label input {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.form-check-label input:checked ~ .cb::before {
  background-color: #fff;
}

.form-check-label input:checked ~ .cb::after {
  transform: scale(1);
}

.form-check-label .cb {
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: 0.1rem;
  left: 0;
  opacity: 1;
}

.form-check-label .cb::before {
  background-color: #fff;
  border: 1px solid #000;
  content: '';
  cursor: pointer;
  display: block;
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: border 200ms ease;
}

.form-check-label .cb::after {
  background: transparent url('/img/icons/tick.svg') top 0.25rem left 0.2rem
    no-repeat;
  background-size: 70%;
  content: '';
  cursor: pointer;
  display: block;
  height: 1rem;
  width: 1rem;
  font-weight: bold;
  line-height: 1.1;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(0);
  text-align: center;
  transition: all 250ms;
}

.form-check-label .filter-tally {
  color: #666;
  font-size: 0.7rem;
}

.theme-sdr input,
.theme-sdr textarea {
  border-radius: 0;
  color: #1b2734;
  font-family: 'Avenir Next', 'Prompt', sans-serif;
  font-size: 0.9rem;
}

.theme-sdr .form-control:focus {
  box-shadow: none;
  outline: thin solid #979797;
}

.theme-sdr legend {
  font-family: 'baron_neue', sans-serif;
}

.theme-sdr .single-line-form {
  max-width: 48rem;
}

.theme-sdr .single-line-form .input-group {
  border-bottom: 2px solid #000;
  padding-bottom: 0.5rem;
}

.theme-sdr .single-line-form input {
  border: 0;
  font-size: 1rem;
  height: auto;
  line-height: 1;
  margin-right: 1rem;
}

.theme-sdr .single-line-form input::-webkit-input-placeholder {
  text-transform: uppercase;
}

.theme-sdr .single-line-form input::-moz-placeholder {
  text-transform: uppercase;
}

.theme-sdr .single-line-form input:-moz-placeholder {
  text-transform: uppercase;
}

.theme-sdr .single-line-form input:-ms-input-placeholder {
  text-transform: uppercase;
}

.theme-sdr .single-line-form input[type='submit'],
.theme-sdr .single-line-form button[type='submit'] {
  font-family: 'baron_neue', sans-serif;
  font-size: 1.1rem;
  letter-spacing: 0;
  line-height: 1;
  min-width: 0;
  padding: 0.5rem;
}

@media (min-width: 768px) {
  .theme-sdr .single-line-form input[type='submit'],
  .theme-sdr .single-line-form button[type='submit'] {
    padding: 0.5rem 3rem;
  }
}

.theme-sdr .single-line-form input[type='submit'].btn-sm,
.theme-sdr .single-line-form button[type='submit'].btn-sm {
  font-size: 0.9rem;
}

.theme-sdr select {
  background: #fff url('/img/icons/chevron.svg') center right 1rem no-repeat;
  border: thin solid #000;
  border-radius: 0;
  color: #000;
  outline: none;
  padding: 0.25rem 3rem 0.25rem 1rem;
  transition: all 200ms ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

.theme-sdr select:hover {
  background-color: #eee;
}

.theme-sdr select:focus,
.theme-sdr select:active {
  background-color: #fff;
  border-color: #000;
}

.theme-sdr select::-ms-expand {
  display: none;
}

.theme-sdr select + label {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  position: relative;
}

.theme-sdr option {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  outline: 0;
}

.theme-sdr .single-line-form.form-invalid button[type='submit'] {
  opacity: 0.5;
  pointer-events: none;
}

.theme-sdr .single-line-form.form-valid button[type='submit'] {
  background: #70a996;
  border-color: #70a996;
}

.theme-sdr .single-line-form .help-inline {
  position: absolute;
  left: 0;
  top: 100%;
}

.theme-sdr .input-group .help-inline {
  display: none !important;
}

.theme-sdr .input-group.invalid {
  border-color: #c10000;
}

.theme-sdr .input-group.invalid .help-inline {
  display: block !important;
  padding: 0.1rem 0.75rem;
}

.theme-sdr .help-inline {
  background: #c10000;
  color: #fff;
  float: left;
  font-size: 0.8rem;
  width: auto;
}

.theme-sdr .item-price {
  margin-bottom: 0px;
}

blockquote {
  display: block;
  font-style: italic;
  font-size: 1.5em;
  border-width: 2px 0;
  border-style: solid;
  border-color: #eee;
  padding: 1.5em 0 1em;
  margin: 1.5em 0;
  position: relative;
  text-align: center;
}

blockquote:before {
  font-family: 'PT Sans', sans-serif, Open Sans;
  font-size: 2em !important;
  content: '\201C';
  position: absolute;
  top: 0em;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  width: 3rem;
  height: 2rem;
  /*font: 6em/1.08em 'PT Sans', sans-serif;*/
  color: #666;
  text-align: center;
}

/*# sourceMappingURL=uCommerce.demostore.css.map */
